import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { AppSettings } from '@configs/app.setting'
import { AppGuard } from '@core/guards/auth.guard'
import { AdminBusinessDetail } from 'src/app/admin/business/ui/admin-business.model'
import { ItemPageConfig } from 'src/app/feature/menu/model/item.model'
import { AuthService } from '@services/auth/auth.service'
import { CustomCookieService } from '@services/cookie/cookie.service'
import { getAdminPrivilage, getAuthKey } from '@core/utils/auth-stuff'
import { getMonitoredBusiness } from '@core/utils/user-stuff'
import { map, Observable, tap } from 'rxjs'
import { StorePayload } from 'src/app/feature/branch/models/store.model'

@Injectable({
  providedIn: 'root',
})
export class StoreRepositoryService {
  private _headers = new HttpHeaders().set('Content-Type', 'application/json')

  constructor(
    private _httpClient: HttpClient,
    private _appGuard: AppGuard,
    private _cookieService: CustomCookieService
  ) {}

  getAllStore(pageConfig: ItemPageConfig): Observable<any> {
    const isAdmin = getAdminPrivilage()
    const monitoredBusiness: AdminBusinessDetail | null = getMonitoredBusiness() || null

    if (isAdmin && monitoredBusiness != null) {
      return this.getMerchantAllStore(pageConfig, monitoredBusiness)
    }

    this._headers = this._headers.set('Authorization', 'Bearer ' + getAuthKey(this._cookieService))
    let url = `${AppSettings.BRANCH_STORE_URL}?`
    for (const config in pageConfig) {
      if (url.charAt(url.length - 1) === '?') {
        url = `${url}${config}=${pageConfig[config]}`
      } else {
        url = `${url}&${config}=${pageConfig[config]}`
      }
    }
    return this._httpClient.get(url, { headers: this._headers }).pipe(
      map((response: any) => response),
      tap({
        next: (response) => response,
        error: (error: any) => {
          if (error instanceof HttpErrorResponse) this._appGuard.checkAccess(error.status)
        },
      })
    )
  }

  deleteStore(id: number): Observable<any> {
    const isAdmin = getAdminPrivilage()
    const monitoredBusiness: AdminBusinessDetail | null = getMonitoredBusiness() || null

    if (isAdmin && monitoredBusiness != null) {
      return this.deleteMerchantStore(id, monitoredBusiness)
    }

    this._headers = this._headers.set('Authorization', 'Bearer ' + getAuthKey(this._cookieService))

    return this._httpClient.delete(`${AppSettings.BRANCH_STORE_URL}/${id}`, { headers: this._headers }).pipe(
      map((response: any) => response),
      tap({
        next: (response) => response,
        error: (error: any) => {
          if (error instanceof HttpErrorResponse) this._appGuard.checkAccess(error.status)
        },
      })
    )
  }

  createStore(data: StorePayload): Observable<any> {
    const isAdmin = getAdminPrivilage()
    const monitoredBusiness: AdminBusinessDetail | null = getMonitoredBusiness() || null

    if (isAdmin && monitoredBusiness != null) {
      return this.createMerchantStore(data, monitoredBusiness)
    }

    this._headers = this._headers.set('Authorization', 'Bearer ' + getAuthKey(this._cookieService))

    return this._httpClient.post(`${AppSettings.BRANCH_STORE_URL}`, data, { headers: this._headers }).pipe(
      map((response: any) => response),
      tap({
        next: (response) => response,
        error: (error: any) => {
          if (error instanceof HttpErrorResponse) this._appGuard.checkAccess(error.status)
        },
      })
    )
  }

  getDetailStore(
    id: number,
    isAuth: boolean = false,
    businessDetail: AdminBusinessDetail | undefined,
    isDropdown: boolean = false
  ): Observable<any> {
    // var monitoredBusiness!: AdminBusinessDetail;

    // if (!isAuth) {
    //   monitoredBusiness = getMonitoredBusiness();

    // }
    const adminPrivilage = getAdminPrivilage()

    if (adminPrivilage && businessDetail != undefined && !isDropdown) {
      return this.getMerchantDetailStore(id, businessDetail)
    }

    if (adminPrivilage && businessDetail != undefined && isDropdown) {
      return this.getMerchantDetailStoreDropdown(id, businessDetail)
    }

    this._headers = this._headers.set('Authorization', 'Bearer ' + getAuthKey(this._cookieService))

    return this._httpClient.get(`${AppSettings.BRANCH_STORE_URL}/${id}`, { headers: this._headers }).pipe(
      map((response: any) => response),
      tap({
        next: (response) => response,
        error: (error: any) => {
          if (error instanceof HttpErrorResponse) this._appGuard.checkAccess(error.status)
        },
      })
    )
  }

  updateStore(data: StorePayload): Observable<any> {
    this._headers = this._headers.set('Authorization', 'Bearer ' + getAuthKey(this._cookieService))

    const isAdmin = getAdminPrivilage()
    const monitoredBusiness: AdminBusinessDetail | null = getMonitoredBusiness() || null

    if (isAdmin && monitoredBusiness != null) {
      return this.updateMerchantStore(data, monitoredBusiness)
    }
    return this._httpClient.put(`${AppSettings.BRANCH_STORE_URL}/${data.id}`, data, { headers: this._headers }).pipe(
      map((response: any) => response),
      tap({
        next: (response) => response,
        error: (error: any) => {
          if (error instanceof HttpErrorResponse) this._appGuard.checkAccess(error.status)
        },
      })
    )
  }

  /** Open ~ Super Admin Business Section */
  getMerchantAllStore(pageConfig: ItemPageConfig, monitoredBusiness: AdminBusinessDetail): Observable<any> {
    this._headers = this._headers.set('Authorization', 'Bearer ' + getAuthKey(this._cookieService))
    let url = `${AppSettings.ADMIN_BUSINESS_SECTION_URL}${monitoredBusiness.id}/${AppSettings.ADMIN_BUSINESS_SECTION_STORE}?`
    for (const config in pageConfig) {
      if (url.charAt(url.length - 1) === '?') {
        url = `${url}${config}=${pageConfig[config]}`
      } else {
        url = `${url}&${config}=${pageConfig[config]}`
      }
    }
    return this._httpClient.get(url, { headers: this._headers }).pipe(
      map((response: any) => response),
      tap({
        next: (response) => response,
        error: (error: any) => {
          if (error instanceof HttpErrorResponse) this._appGuard.checkAccess(error.status)
        },
      })
    )
  }

  deleteMerchantStore(id: number, monitoredBusiness: AdminBusinessDetail): Observable<any> {
    this._headers = this._headers.set('Authorization', 'Bearer ' + getAuthKey(this._cookieService))

    let url = `${AppSettings.ADMIN_BUSINESS_SECTION_URL}${monitoredBusiness.id}/${AppSettings.ADMIN_BUSINESS_SECTION_STORE}/${id}`
    return this._httpClient.delete(url, { headers: this._headers }).pipe(
      map((response: any) => response),
      tap({
        next: (response) => response,
        error: (error: any) => {
          if (error instanceof HttpErrorResponse) this._appGuard.checkAccess(error.status)
        },
      })
    )
  }

  createMerchantStore(data: StorePayload, monitoredBusiness: AdminBusinessDetail): Observable<any> {
    this._headers = this._headers.set('Authorization', 'Bearer ' + getAuthKey(this._cookieService))

    let url = `${AppSettings.ADMIN_BUSINESS_SECTION_URL}${monitoredBusiness.id}/${AppSettings.ADMIN_BUSINESS_SECTION_STORE}`
    return this._httpClient.post(url, data, { headers: this._headers }).pipe(
      map((response: any) => response),
      tap({
        next: (response) => response,
        error: (error: any) => {
          if (error instanceof HttpErrorResponse) this._appGuard.checkAccess(error.status)
        },
      })
    )
  }

  getMerchantDetailStore(id: number, monitoredBusiness: AdminBusinessDetail): Observable<any> {
    this._headers = this._headers.set('Authorization', 'Bearer ' + getAuthKey(this._cookieService))

    let url = `${AppSettings.ADMIN_BUSINESS_SECTION_URL}${monitoredBusiness.id}/${AppSettings.ADMIN_BUSINESS_SECTION_STORE}/${id}`
    // let url = `${AppSettings.BUSINESS_UTILITIES_URL}/${AppSettings.BUSINESS_UTILITIES_INFO}?${AppSettings.BUSINESS_UTILITIES_ID}=${id}`
    return this._httpClient.get(url, { headers: this._headers }).pipe(
      map((response: any) => response),
      tap({
        next: (response) => response,
        error: (error: any) => {
          if (error instanceof HttpErrorResponse) this._appGuard.checkAccess(error.status)
        },
      })
    )
  }

  getMerchantDetailStores(id: number): Observable<any> {
    this._headers = this._headers.set('Authorization', 'Bearer ' + getAuthKey(this._cookieService))

    let url = `${AppSettings.ADMIN_BUSINESS_SECTION_URL}${id}`
    // let url = `${AppSettings.BUSINESS_UTILITIES_URL}/${AppSettings.BUSINESS_UTILITIES_INFO}?${AppSettings.BUSINESS_UTILITIES_ID}=${id}`
    return this._httpClient.get(url, { headers: this._headers }).pipe(
      map((response: any) => response),
      tap({
        next: (response) => response,
        error: (error: any) => {
          if (error instanceof HttpErrorResponse) this._appGuard.checkAccess(error.status)
        },
      })
    )
  }

  getMerchantDetailStoreDropdown(id: number, monitoredBusiness: AdminBusinessDetail): Observable<any> {
    this._headers = this._headers.set('Authorization', 'Bearer ' + getAuthKey(this._cookieService))
    let url = `${AppSettings.ADMIN_BUSINESS_SECTION_URL}${monitoredBusiness.id}/${AppSettings.ADMIN_BUSINESS_SECTION_STORE}/${id}`
    return this._httpClient.get(url, { headers: this._headers }).pipe(
      map((response: any) => response),
      tap({
        next: (response) => response,
        error: (error: any) => {
          if (error instanceof HttpErrorResponse) this._appGuard.checkAccess(error.status)
        },
      })
    )
  }

  updateMerchantStore(data: StorePayload, monitoredBusiness: AdminBusinessDetail): Observable<any> {
    this._headers = this._headers.set('Authorization', 'Bearer ' + getAuthKey(this._cookieService))

    let url = `${AppSettings.ADMIN_BUSINESS_SECTION_URL}${monitoredBusiness.id}/${AppSettings.ADMIN_BUSINESS_SECTION_STORE}/${data.id}`
    return this._httpClient.put(url, data, { headers: this._headers }).pipe(
      map((response: any) => response),
      tap({
        next: (response) => response,
        error: (error: any) => {
          if (error instanceof HttpErrorResponse) this._appGuard.checkAccess(error.status)
        },
      })
    )
  }
  /** Close ~ Super Admin Business Section */
}

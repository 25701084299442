import { CommonModule } from '@angular/common'
import { HttpClient, HttpClientModule } from '@angular/common/http'
import { NgModule } from '@angular/core'
import { TranslateModule } from '@ngx-translate/core'
import { TranslateHttpLoader } from '@ngx-translate/http-loader'
import { TranslateComponent } from './directives/translate/translate.component'

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json')
}

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    // TranslateModule.forRoot({
    //   useDefaultLang: true,
    //   defaultLanguage: 'en_US',
    //   loader: {
    //     provide: TranslateLoader,
    //     useFactory: HttpLoaderFactory,
    //     deps: [HttpClient],
    //     multi: true
    //   },
    // }),
  ],
  declarations: [TranslateComponent],
  exports: [TranslateComponent, TranslateModule],
})
export class SharedModule {}

import { AppSettings } from '@configs/app.setting'
import { MENU_INDEXES } from '@models/menu-index'

export const adminMenuList = [
  {
    id: MENU_INDEXES.MENU_DASHBOARD_ID,
    codes: [],
    title: 'BUSINESS_SIDEBAR.DASHBOARD',
    alias: 'dashboard',
    routerLink: `/${AppSettings.ADMIN_ROUTE}/${AppSettings.DASHBOARD_MENU}`,
    icon: 'dashboard',
    iconSec: 'dashboard-sec',
    active: 'active-business',
  },
  {
    id: MENU_INDEXES.MENU_BUSINESS_CREATE,
    codes: [AppSettings.IDESHOP_SA_BUSINESS],
    title: 'BUSINESS_SIDEBAR.CREATE_BUSINESS',
    alias: 'create-business',
    routerLink: `/${AppSettings.ADMIN_ROUTE}/${AppSettings.BUSINESS_MENU}/${AppSettings.BUSINESS_CREATE_MENU}`,
    icon: 'create-business',
    iconSec: 'create-business-sec',
    active: 'active-business',
    isCreatable: false,
  },
  {
    id: MENU_INDEXES.MENU_BUSINESS_MANAGEMENT,
    codes: [AppSettings.IDESHOP_SA_BUSINESS],
    title: 'BUSINESS_SIDEBAR.BUSINESS_MANAGEMENT',
    alias: 'business-management',
    routerLink: `/${AppSettings.ADMIN_ROUTE}/${AppSettings.BUSINESS_MENU}`,
    icon: 'business-management',
    iconSec: 'business-management-sec',
    active: 'active-business',
    isCreatable: false,
    isUpdatable: false,
    isDeletable: false,
  },
  // {
  //   id: MENU_INDEXES.MENU_NPS_REPORT,
  //   codes: [AppSettings.IDESHOP_SA_BUSINESS],
  //   title: 'BUSINESS_SIDEBAR.NPS_REPORT',
  //   alias: 'nps-report',
  //   routerLink: `/${AppSettings.ADMIN_ROUTE}/${AppSettings.NPS_REPORT_MENU}`,
  //   icon: 'nps-report',
  //   iconSec: 'nps-report-sec',
  //   active: 'active-business',
  //   isCreatable: false,
  //   isUpdatable: false,
  //   isDeletable: false,
  // },
  {
    id: MENU_INDEXES.MENU_CLOUD_PRINTER,
    codes: [AppSettings.IDESHOP_SA_CLOUD_PRINTER],
    title: 'BUSINESS_SIDEBAR.CLOUD_PRINTER',
    alias: 'cloud-printer',
    routerLink: `/${AppSettings.ADMIN_ROUTE}/${AppSettings.CLOUD_PRINTER}`,
    icon: 'cloud-printer',
    iconSec: 'cloud-printer-sec',
    active: 'active-business',
    isCreatable: false,
    isUpdatable: false,
    isDeletable: false,
  },
  {
    id: MENU_INDEXES.MENU_PAYMENT_INTEGRATION_ID,
    codes: [AppSettings.IDESHOP_SA_INTEGRATION_PAYMENT],
    title: 'BUSINESS_SIDEBAR.PAYMENT_INTEGRATION',
    alias: 'payment-integration',
    icon: 'payment-integration',
    iconSec: 'payment-integration',
    active: 'active-business',
    showDropdown: false,
    routerLink: '',
    subtitle: [
      {
        id: MENU_INDEXES.MENU_PAYMENT_INTEGRATION_KPAY_ID,
        codes: [AppSettings.IDESHOP_SA_INTEGRATION_PAYMENT],
        name: 'BUSINESS_SIDEBAR.KPAY',
        alias: 'kpay',
        isCreatable: false,
        isUpdatable: false,
        isDeletable: false,
        routerLink: `/${AppSettings.ADMIN_ROUTE}/${AppSettings.PAYMENT_INTEGRATION_KPAY_MENU}`,
      },
    ],
  },
  {
    id: MENU_INDEXES.MENU_LOG_REPORT,
    codes: [AppSettings.IDESHOP_SA_LOG_PARENT],
    title: 'BUSINESS_SIDEBAR.LOG_REPORT',
    alias: 'log-report',
    routerLink: `/${AppSettings.ADMIN_ROUTE}/${AppSettings.LOG_REPORT_MENU}`,
    icon: 'log-report',
    iconSec: 'log-report-sec',
    active: 'active-business',
    isCreatable: false,
    isUpdatable: false,
    isDeletable: false,
    showDropdown: false,
    subtitle: [
      {
        id: MENU_INDEXES.MENU_LOG_REPORT_APP_ID,
        codes: [AppSettings.IDESHOP_SA_LOG_PARENT],
        name: 'BUSINESS_SIDEBAR.LOG_REPORT_APP',
        alias: 'log-report',
        isCreatable: false,
        isUpdatable: false,
        isDeletable: false,
        routerLink: `${AppSettings.ADMIN_ROUTE}/${AppSettings.LOG_REPORT_MENU}/${'idepos'}`,
      },
      {
        id: MENU_INDEXES.MENU_LOG_REPORT_USER_ID,
        codes: [AppSettings.IDESHOP_SA_LOG_PARENT],
        name: 'BUSINESS_SIDEBAR.LOG_REPORT_USER',
        alias: 'log-report',
        isCreatable: false,
        isUpdatable: false,
        isDeletable: false,
        routerLink: `${AppSettings.ADMIN_ROUTE}/${AppSettings.LOG_REPORT_MENU}/${'user'}`,
      },
      {
        id: MENU_INDEXES.MENU_LOG_REPORT_IDEFOOD_ID,
        codes: [AppSettings.IDESHOP_SA_LOG_PARENT],
        name: 'BUSINESS_SIDEBAR.LOG_REPORT_IDEFOOD',
        alias: 'log-report',
        isCreatable: false,
        isUpdatable: false,
        isDeletable: false,
        routerLink: `${AppSettings.ADMIN_ROUTE}/${AppSettings.LOG_REPORT_MENU}/${'idefood'}`,
      },
    ],
  },
  {
    id: MENU_INDEXES.MENU_INTEGRATION_DELIVERY,
    codes: [AppSettings.IDESHOP_SA_INTEGRATION_DELIVERY_PARENT],
    title: 'BUSINESS_SIDEBAR.SIDENAV_INTEGRATION_DELIVERY',
    alias: 'admin-integration-delivery',
    routerLink: `/${AppSettings.ADMIN_ROUTE}/${AppSettings.INTEGRATION_DELIVERY_MENU}`,
    icon: 'integration-delivery',
    iconSec: 'integration-delivery-sec',
    active: 'integration-delivery-active',
    isCreatable: false,
    isUpdatable: false,
    isDeletable: false,
  },
  {
    id: MENU_INDEXES.MENU_INTEGRATION_DELIVERY,
    codes: [AppSettings.IDESHOP_SA_BUSINESS],
    title: 'BUSINESS_SIDEBAR.SIDENAV_INTEGRATION_DELIVERY',
    alias: 'admin-integration-delivery',
    routerLink: `/${AppSettings.ADMIN_ROUTE}/${AppSettings.INTEGRATION_DELIVERY_MENU}`,
    icon: 'integration-delivery',
    iconSec: 'integration-delivery-sec',
    active: 'integration-delivery-active',
    isCreatable: false,
    isUpdatable: false,
    isDeletable: false,
  },
  {
    id: MENU_INDEXES.MENU_SETTING_SA_ID,
    codes: [AppSettings.IDESHOP_SA_SETTING_CASH_DRAWER],
    title: 'BUSINESS_SIDEBAR.SETTING',
    alias: 'setting-superadmin',
    icon: 'setting-superadmin',
    iconSec: 'setting-superadmin',
    active: 'active-business',
    showDropdown: false,
    routerLink: '',
    subtitle: [
      {
        id: MENU_INDEXES.MENU_CASH_DRAWER_ID,
        codes: [AppSettings.IDESHOP_SA_SETTING_CASH_DRAWER],
        name: 'BUSINESS_SIDEBAR.CASH_DRAWER',
        alias: 'cash_drawer',
        isCreatable: false,
        isUpdatable: false,
        isDeletable: false,
        routerLink: `/${AppSettings.ADMIN_ROUTE}/${AppSettings.SETTING_CASH_DRAWER_MENU}`,
      },
    ],
  },
  {
    id: MENU_INDEXES.MENU_MARKETING_AND_FINANCE_ID,
    codes: [AppSettings.IDESHOP_SA_MARKETING_AND_FINANCE_PARENT],
    title: 'BUSINESS_SIDEBAR.MARKETING_AND_FINANCE',
    alias: 'marketing_and_finance',
    icon: 'marketing_and_finance',
    iconSec: 'role-sec',
    active: 'active-business',
    showDropdown: false,
    routerLink: '',
    subtitle: [
      {
        id: MENU_INDEXES.MENU_PERFORMANCE_ID,
        codes: [AppSettings.IDESHOP_SA_MARKETING_AND_FINANCE_PARENT],
        name: 'BUSINESS_SIDEBAR.PERFORMANCE',
        alias: 'log-report',
        routerLink: `/${AppSettings.ADMIN_ROUTE}/${AppSettings.PERFORMANCE_MENU}`,
        isCreatable: false,
        isUpdatable: false,
        isDeletable: false,
      },
      {
        id: MENU_INDEXES.MENU_BANK_ACCOUNT_ID,
        codes: [AppSettings.IDESHOP_SA_MARKETING_AND_FINANCE_PARENT],
        name: 'BUSINESS_SIDEBAR.BANK_ACCOUNT',
        alias: 'bank-account',
        routerLink: `/${AppSettings.ADMIN_ROUTE}/${AppSettings.BANK_ACCOUNT_MENU}`,
        isCreatable: false,
        isUpdatable: false,
        isDeletable: false,
      },
      // {
      //   id: MENU_INDEXES.MENU_HARDWARE_ID,
      //   codes: [AppSettings.IDESHOP_SA_BUSINESS],
      //   name: 'BUSINESS_SIDEBAR.HARDWARE',
      //   alias: 'hardware',
      //   routerLink: `/${AppSettings.ADMIN_ROUTE}/${AppSettings.HARDWARE_MENU}`,
      //   isCreatable: false,
      //   isUpdatable: false,
      //   isDeletable: false,
      // },
    ],
  },
  {
    id: MENU_INDEXES.MENU_USER_PERMISSION_ID,
    codes: [AppSettings.IDESHOP_SA_ROLE, AppSettings.IDESHOP_SA_USER],
    title: 'PERMISSION.USER_PERMISSION_TITLE',
    alias: 'role',
    icon: 'role',
    iconSec: 'role-sec',
    active: 'active-business',
    showDropdown: false,
    routerLink: `${AppSettings.ADMIN_ROUTE}/${AppSettings.ROLE_MENU}`,
    subtitle: [
      {
        id: MENU_INDEXES.MENU_USER_DETAIL_ID,
        codes: [AppSettings.IDESHOP_SA_USER],
        name: 'PERMISSION.USER_TITLE',
        alias: 'user',
        isCreatable: false,
        isUpdatable: false,
        isDeletable: false,
        routerLink: `${AppSettings.ADMIN_ROUTE}/${AppSettings.ROLE_MENU}/${AppSettings.ROLE_USER_MENU}`,
      },
      {
        id: MENU_INDEXES.MENU_USER_ROLE_ID,
        codes: [AppSettings.IDESHOP_SA_ROLE],
        name: 'PERMISSION.ROLE_TITLE',
        alias: 'role',
        isCreatable: false,
        isUpdatable: false,
        isDeletable: false,
        routerLink: `${AppSettings.ADMIN_ROUTE}/${AppSettings.ROLE_MENU}/${AppSettings.ROLE_MANAGEMENT_MENU}`,
      },
    ],
  },
]

import { IEndpoint } from '../models/integration.model'

export const INTEGRATION_PAYMENT_ENDPOINTS: IEndpoint = {
  connection: {
    merchant: '/api-payment/portal/v1/integration/payment/payment-online',
    monitorSuperAdmin: '/api-payment/portal/v1/admin/business-section/{id}/integration/payment/payment-online',
  },
  paymentAvailable: {
    merchant: '/api-payment/portal/v1/integration/payment/payment-online',
    monitorSuperAdmin: '/api-payment/portal/v1/admin/business-section/{id}/integration/payment/payment-online',
  },
  paymentMethod: {
    merchant: '/api-payment/portal/v1/integration/payment/payment-online/method',
    monitorSuperAdmin: '/api-payment/portal/v1/admin/business-section/{id}/integration/payment/payment-online/method',
  },
  paymentFee: {
    superAdmin: '/integration/payment/payment-online/method/fee'
  },
  kpay: {
    detailAccount: {
      superAdmin: '/api-kpay/account/:storeId',
    },
    listTerminal: {
      merchant: '/api-kpay/terminal',
      monitorSuperAdmin: '/terminal',
    },
    listStore: {
      superAdmin: '/api-kpay/account',
    },
    updateAccount: {
      superAdmin: '/api-kpay/account/update',
      merchant: '/api-kpay/account/update',
    },
    terminalStatus: {
      superAdmin: '/api-kpay/static-data/terminal-status',
    },
    register: {
      merchant: '/api-kpay/account/register',
      monitorSuperAdmin: '',
    },
    integrationTerminalStatus: {
      superAdmin: '/api-kpay/static-data/integration-status'
    }
  },
}

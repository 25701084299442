// import { ActivatedRoute } from "@angular/router" 

// export const IDEKU_URL = (environment.production ? 'sell.ideku.id/' : 'stg-sell.ideku.id/')

// const act:ActivatedRoute = new ActivatedRoute;
    
// export const IDEKU_URL = location.host.substring(0, 3) == 'stg'
//     ? 'stg-sell.ideku.id/'
//     : 'sell.ideku.id/'

export const IDEKU_URL = "ideku.io";
export const IDEKU_URL_PATHABLE = "stg-sell.ideku.id";
export const TOP_DOMAIN_IDEKU_URL = "io";
export const IDEKU_URL_DIGITAL = "ideku.digital";
export const TOP_DOMAIN_IDEKU_DIGITAL = "digital";
export const IDEKU_PREVIEW_URL = "sell.ideku.id/";



// Register Merchant Steps
export const REGISTER_PARTICULARS = 'Your Particulars'
export const BUSINESS_INFORMATION = 'Business Information'
export const SALES_CHANNELS = 'Sales Channels'
export const PRODUCTS = 'Products'
export const COMPLETE_REGISTRATION = 'Complete Registration'


// Default color for platform settings
export const HEADER_BACKGROUND = "#E4E5FD";
export const HEADER_TEXT = "#072446";
export const HEADER_BACKGROUND_HIGHLIGHTED = "#E4E5FD";
export const HEADER_TEXT_HIGHLIGHTED = "#072446";
export const MAIN_BUTTON_BACKGROUND = "#7079FF";
export const MAIN_BUTTON_TEXT = "#FFFFFF";
export const MAIN_BUTTON_BORDER = "#7079FF";
export const ADDITIONAL_BUTTON_BACKGROUND = "#FFFFFF";
export const ADDITIONAL_BUTTON_TEXT = "#7079FF";
export const ADDITIONAL_BUTTON_BORDER = "#7079FF";
export const DISABLED_BUTTON_BACKGROUND = "#C4C4C4";
export const DISABLED_BUTTON_TEXT = "#FFFFFF";
export const DISABLED_BUTTON_BORDER = "#C4C4C4";
export const EMAIL_HEADER_BACKGROUND = "#E4E5FD";
export const EMAIL_HEADER_TEXT = "#7079FF";
export const EMAIL_ACCENT = "#7079FF";
export const EMAIL_LINK = "#7079FF";
export const EMAIL_BUTTON_BACKGROUND = "#7079FF";
export const EMAIL_BUTTON_TEXT = "#FFFFFF";
export const EMAIL_BUTTON_BORDER = "#7079FF";
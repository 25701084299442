import WORDS from '../../../../../../assets/i18n/id_ID.json'

export const sidebarViewAdmin = [
  {
    title: WORDS.ADMIN_SIDEBAR.DASHBOARD,
    alias: 'dashboard',
    routerLink: '/admin/dashboard',
    icon: 'dashboard',
    active: 'active-business',
  },
  {
    title: WORDS.ADMIN_SIDEBAR.BUSINESS,
    alias: 'business',
    routerLink: '/admin/business',
    icon: 'businesses',
    active: 'active-business',
  },
  {
    title: WORDS.ADMIN_SIDEBAR.ADMIN_USERS,
    alias: 'admin-users',
    routerLink: '/admin/admin-users',
    icon: 'user-admin',
    active: 'active-business',
  },
  {
    title: WORDS.ADMIN_SIDEBAR.ROLE,
    alias: 'role',
    routerLink: '/admin/role',
    icon: 'admin',
    active: 'active-business',
  },
  {
    title: WORDS.ADMIN_SIDEBAR.ACTIVITY_LOG,
    alias: 'activity-log',
    routerLink: '/admin/activity-log',
    icon: 'activity-log',
    active: 'active-business',
  },
]

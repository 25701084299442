import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { AppSettings } from '@configs/app.setting'
import { AppGuard } from '@core/guards/auth.guard'
import { AdminBusinessDetail } from 'src/app/admin/business/ui/admin-business.model'
import { DownloadRequest, Pagination, ReqPagination, ResponseModel } from '@core/models/common.model'
import { StoreRepositoryService } from 'src/app/feature/branch/data-access/repository/store-repository/store-repository.service'
import { AdminBusinessService } from '@services/admin-business/admin-business.service'
import { AuthService } from '@services/auth/auth.service'
import { CustomCookieService } from '@services/cookie/cookie.service'
import { map, Observable, tap } from 'rxjs'
import { environment } from 'src/environments/environment'

@Injectable({
  providedIn: 'root',
})
export class BranchRepositoryService {
  constructor(
    private _authService: AuthService,
    private _httpClient: HttpClient,
    private _businessService: AdminBusinessService,
    private _storeRepository: StoreRepositoryService,
    private _cookieService: CustomCookieService,
    protected appGuard: AppGuard
  ) {}

  branchStoreList(pageConfig: ReqPagination | any): Observable<Pagination> {
    const authKey = this._cookieService.getItem(
      environment.production ? AppSettings.AUTH_KEY : AppSettings.AUTH_KEY_DEV
    )
    let headers = new HttpHeaders().set('Content-Type', 'application/json')
    headers = headers.set('Authorization', 'Bearer ' + authKey)
    let url: any = `${AppSettings.BRANCH_STORE_URL}?`
    for (const config in pageConfig) {
      if (url.charAt(url.length - 1) === '?') {
        url = `${url}${config}=${pageConfig[config]}`
      } else {
        url = `${url}&${config}=${pageConfig[config]}`
      }
    }
    return this._httpClient.get(url, { headers: headers }).pipe(
      map((response: Pagination | any) => response),
      tap({
        next: (response) => response,
        error: (err: any) => {
          if (err instanceof HttpErrorResponse) this.appGuard.checkAccess(err.status)
        },
      })
    )
  }

  getBranchStoreById(storeId: number): Observable<any> {
    // const isAdmin: boolean = this._authService.getAdminPrivilage();
    // const businessDetail: AdminBusinessDetail | null = this._businessService.getMonitoredBusiness() || null;

    // if (isAdmin && businessDetail) {
    //   this._storeRepository.getDetailStore(storeId)
    // }

    let headers = new HttpHeaders().set('Content-Type', 'application/json')
    headers = headers.set('Authorization', 'Bearer ' + this._authService.getAuthKey())

    return this._httpClient
      .get(`${AppSettings.BRANCH_STORE_URL}/${storeId}`, {
        headers: headers,
      })
      .pipe(
        map((response: any) => response),
        tap({
          next: (response) => response,
          error: (err: any) => {
            if (err instanceof HttpErrorResponse) this.appGuard.checkAccess(err.status)
          },
        })
      )
  }
}

import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';

@Injectable()
export class HttpResponseErrorInterceptor implements HttpInterceptor {

  constructor(protected router: Router, private _spinner: NgxSpinnerService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    return next.handle(request).pipe( (_httpErrorResponse: any) => {
      return _httpErrorResponse;
    });
  }
}

import { Inject, Injectable, PLATFORM_ID } from '@angular/core'
import { ActivatedRoute, CanActivate, Router } from '@angular/router'
import { COOKIE_NAME_LIST } from '@core/models/auth.model'
import { ErrorCustom } from '@core/models/common.model'
import { AuthService } from '@services/auth/auth.service'
import { CommonService } from '@services/common/common.service'
import { CustomCookieService } from '@services/cookie/cookie.service'
import { ViewService } from '@services/view/view.service'
import { appVerChecker, getAuthKey, getEmail, reValidateAccount } from '@core/utils/auth-stuff'
import { NgxSpinnerService } from 'ngx-spinner'

@Injectable()
export class AppGuard implements CanActivate {
  _platformId!: Object

  constructor(
    @Inject(PLATFORM_ID) platformId: Object,
    private _spinner: NgxSpinnerService,
    private _viewService: ViewService,
    private _commonService: CommonService,
    private _customCookieService: CustomCookieService,
    private _cookieService: CustomCookieService,
    protected router: Router,
    protected activatedRoute: ActivatedRoute,
    protected authService: AuthService
  ) {
    this._platformId = platformId
  }

  canActivate() {
    const isLoggedin = getAuthKey(this._customCookieService) !== null && getEmail() !== null
    if ((isLoggedin || reValidateAccount(this._cookieService, this._platformId)) && appVerChecker()) {
      // logged in so return true
      return true
    } else {
      localStorage.clear()
      COOKIE_NAME_LIST.map((cookieName) => this._customCookieService.removeItem(cookieName))
      this.router.navigate(['/login'])
    }

    return false
  }

  passAccess() {
    if (reValidateAccount(this._cookieService, this._platformId)) {
      this.router.navigate([this._commonService.getDashboardUrl()])
    } else {
      this.router.navigate(['/login'])
    }
  }

  checkAccess(status: number) {
    switch (true) {
      case status == 0:
        this._viewService.setErrorPage(true)
        this._commonService.errorPage.next(true)
        return
      case status == 403 || status == 401:
        this.router.navigate(['/login'])
        return
      // case status == 404:
      //   this.router.navigate(['./not-found'])
      //   return
      case status >= 500:
        // this._viewService.setErrorPage(true)
        // this._commonService.errorPage.next(true)
        // const newErrorCustom: ErrorCustom = new ErrorCustom()
        return
      default:
        this._spinner.hide()
        return
    }
  }
}

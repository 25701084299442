import { Injectable } from '@angular/core'
import { Router, Resolve, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router'
import { AppGuard } from '@core/guards/auth.guard'
import { CommonRepositoryService } from '@repository/common-repository/common-repository.service'
import { Observable, of } from 'rxjs'
import { tap } from 'rxjs/operators'

@Injectable({
  providedIn: 'root',
})
export class PhoneCodeResolver implements Resolve<any> {
  constructor(protected appGuard: AppGuard, private _commonRepository: CommonRepositoryService) {}
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    return this._commonRepository.phoneCountryCodeList().pipe(
      tap({
        next: (response: any) => response,
        error: (httpErrorResponse: any) => {
          this.appGuard.checkAccess(httpErrorResponse.status)
          return httpErrorResponse.error
        },
      })
    )
  }
}

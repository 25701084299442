const BUSINESS_TYPE: string = 'merchant-business-type'

export function setBusinessType(businessType: string) {
  localStorage.setItem(BUSINESS_TYPE, businessType)
}

export function getBusinessType(): string | any {
  let result: any = localStorage.getItem(BUSINESS_TYPE)
  return result
}

import { HttpErrorResponse } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Router, Resolve, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router'
import { AppGuard } from '@core/guards/auth.guard'
import { UserRepository } from '@repository/user-repository/user-repository.service'
import { CustomCookieService } from '@services/cookie/cookie.service'
import { setBusinessLogo } from '@utils/user-stuff'
import { Observable, of, tap } from 'rxjs'

@Injectable({
  providedIn: 'root',
})
export class StoreInfoResolver implements Resolve<any> {
  constructor(private _userRepository: UserRepository, protected appGuard: AppGuard) {}
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    return this._userRepository.getStoreInfo().pipe(
      tap({
        next: (response: any) => {
          setBusinessLogo(response.data?.brandLogoUrl)
        },
        error: (httpErrorResponse: HttpErrorResponse) => {
          this.appGuard.checkAccess(httpErrorResponse.status)
          return httpErrorResponse.error
        },
      })
    )
  }
}

import { PhoneCode } from '@core/models/common.model'

/**
 * This utility is temporary needed
 * will be deleted after get phoneCodeList on Common Service is stable
 */

export const TempPhoneList: PhoneCode[] = [
  { code: 'SG', name: 'Singapore', phoneInitial: 65, phoneLabel: '+65' },
  { code: 'ID', name: 'Indonesia', phoneInitial: 62, phoneLabel: '+62' },
  { code: 'KH', name: 'Cambodia', phoneInitial: 855, phoneLabel: '+855' },
]

import { Component, OnInit } from '@angular/core'
import { CommonService } from '@services/common/common.service'
import { NgxSpinnerService } from 'ngx-spinner'

@Component({
  selector: 'app-redirect-routing',
  template: '',
})
export class ReloadComponent implements OnInit {
  constructor(private _spinner: NgxSpinnerService, private _commonService: CommonService) {}

  ngOnInit(): void {
    this._spinner.show()
  }
}

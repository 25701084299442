import { PageableModel, SortModel } from './common.model'

export class AdminModel {
  active!: boolean
  email!: string
  emailLanguage!: string
  firstName!: string
  id!: string
  lastName!: string
}

export class EditUserForm {
  email!: string
  firstName!: string
  lastName!: string
  phone!: string
}

export class AdminSaveModel {
  active!: boolean
  email!: string
  emailLanguage!: string
  firstName!: string
  id!: string
  lastName!: string
  password!: string
  roleId!: number
}

export class Need2FA {
  deviceId!: string
  phone!: string
  phoneCode!: string
  email!: string
  code!: string

  user?: UserContent
  deviceKey?: string
  userKey?: string
}

export class Authority {
  section!: string
  items!: string[]
}

export class Role {
  active!: boolean
  id!: number
  name!: string
  authLevel!: string
  authorities!: Authority[]
}

export interface LoginModel {
  deviceId?: string
  userKey?: string
  deviceKey?: string
  email?: string
  password?: string
  rememberDevice?: boolean
  code?: string
  need2FA?: boolean
  code1?: number
  code2?: number
  code3?: number
  code4?: number
  code5?: number
  code6?: number
  user?: UserContent
  phone?: string
  phoneCode?: string
}

export class UserStatisticResponse {
  error!: string

  totalUsersToday!: number
  totalUsersThisMonth!: number
  totalUsersThisYear!: number
}

export class LoginResponse {
  error!: string
  userKey!: string
  deviceId!: string
  deviceKey!: string
  code!: string
  password!: string
  id!: string
  email!: string
  status!: boolean
  rememberMe!: boolean
  user!: UserContent
  data!: LoginModel
}

export class VerifyResponse {
  deviceKey!: string
  code!: string
  error!: string
  data!: Need2FA

  userKey!: string
}

/*export class LoginRes{
  userKey: string;
}*/

export class UserResponse {
  search!: string
  status!: number
  error!: string
  code!: string
  user!: UserContent
  users!: UserModel
  page!: UserPageModel
  data: UserContent[] = []
}

export class ForgotPasswordResponse {
  timestamp!: number
  code!: string
  status!: number
  error?: string
}

export class UserModel2 {
  last!: boolean
  totalPages!: number
  totalElements!: number
  numberOfElements!: number
  first!: boolean
  size!: number
  number!: number
  sort!: SortModel
  pageable!: PageableModel
  content: UserContent[] = []
}

export class UserPageModel {
  page!: number
  totalPage!: number
  itemsPerPage!: number
  totalItem!: number
  totalElements!: number
}

export class UserModel {
  last!: boolean
  totalPages!: number
  totalElements!: number
  numberOfElements!: number
  first!: boolean
  size!: number
  number!: number
  sort!: SortModel
  pageable!: PageableModel
  content: UserContent[] = []
}

export class UserContent {
  id!: string
  phone!: string
  // authKey: string;
  deviceId!: string
  userKey!: string
  passwordResetToken!: string
  validationToken!: string
  resetToken!: string
  enabled!: boolean
  firstName!: string
  lastName!: string
  nationality!: string
  passport!: string
  role!: any
  password!: string
  address!: string
  status!: number
  active!: boolean
  email!: string
  createdAt!: Date
  updatedAt!: Date
  authorities!: Authorities[]
  accountNonExpired!: boolean
  accountNonLocked!: boolean
  credentialsNonExpired!: boolean
  username!: string
  photoFileId!: string
  backICFileId!: string
  frontICFileId!: string
  contactNumber!: string
  data!: UserContent
  emailLanguage!: string
  merchantId!: string
}

export class Authorities {
  authority!: string
}

export class FilterUserContent {
  status!: number
  search!: string
  sorting!: string
  asc!: boolean
}

export class USER_PAGE {
  public static LIST = '/pages/user'
}

export class UserStatus {
  public static STATUS_UNVERIFIED = 10
  public static STATUS_DELETED = 0
  public static STATUS_APPROVED = 2
  public static STATUS_REJECTED = 12
  public static STATUS_SUBMITTED = 11
}

export class ForgotPasswordForm {
  email!: string
  deviceId!: string
}

export class ResetPasswordForm {
  deviceId!: string
  password!: string
  token!: string
}

export class ChangePasswordForm {
  currentPassword!: string
  newPassword!: string
}

export class ForgotPassword {
  status!: number
  code!: string
  error!: string
  password!: string
  passwordConfirm!: string
  token!: string
}

export class RegisterForm {
  email!: string
  firstName!: string
  lastName!: string
  password!: string
  phone!: string
  phoneCode!: string
  deviceId!: string
  businessName!: string
}

export class ParticularsForm {
  countryId!: string
  email!: string
  firstName!: string
  lastName!: string
  phone!: string
  phoneCode!: string
}

export class StoreInfoForm {
  businessName!: string
  countryId!: string
  currency!: string
  description!: string
  email!: string
  locale!: string
  template!: string
}

export class MerchantAuthData {
  id!: number
  name!: string
  primaryEmail!: string
  authKey!: string
  validated?: boolean
  active?: boolean
}

export interface MerchantAuth {
  code?: string
  status?: number
  error?: string
  email: string
  password: string
  businessName?: string
  name?: string
  data?: MerchantAuthData
  authType?: string
  phoneNumber?: string
  phoneCountryCode?: string
  template?: string
  socketToken?: string
  subscriptionType?: string
  basicIdentityNotComplete?: boolean
  emptyPassword?: boolean
  countProducts?: number
  countMerchantChannel?: number
  country?: string
  currency?: string
}

export class MethodList {
  code!: string
  name!: string
}

export class MethodListResponse {
  code!: string
  status!: number
  data!: MethodList[]
}

export class MerchantUpdatePassword {
  currentPassword!: string
  password!: string
  passwordConfirm!: string
}

export function getStatusText(status: boolean) {
  switch (status) {
    case true:
      return 'Active'
    case false:
      return 'Inactive'
  }
}

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  localhost: false,
  prefixUrl: 'stg-api.',
  urlRetail: 'https://stg.ideshop.io/',
  urlFnB: 'https://stg.idefood.io/',
  urlQueue: 'https://stg-ideque.idefood.io',
  urlQueueBoard: 'https://stg-board.ideku.io',
  domain: 'stg-idepos.io',
  sentryUrl: 'https://a2f4a40d2f98425d97eb58822ebc5e3b@o4505117667426304.ingest.sentry.io/4505117740630016',
  sentrySampleRate: 0.0,
  apiUrl: 'https://stg-api.ideku.id/api',
  version: '4.6.11',
  mapKey: 'AIzaSyDXYIelvc9taXv5KTXwtUMQTqE7E21DvxY',
  firebase: {
    apiKey: 'AIzaSyBLwy-eJJKWNuQXAkt-7mu_eO1sGmcHjeI',
    authDomain: 'ideku-project.firebaseapp.com',
    projectId: 'ideku-project',
    storageBucket: 'ideku-project.appspot.com',
    messagingSenderId: '204807868077',
    appId: '1:204807868077:web:f09ad0a2b3a2948c63c024',
    measurementId: 'G-P26XFLFBBN',
  },
  landingPageUrl: 'https://stg.ideku.id/',
  mode:'stg'
}

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.

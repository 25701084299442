import { List } from '../data-access/models/order-fnb.model'

export const OrderTypeList: List[] = [
  { code: 'DINE_IN', name: 'ORDERS.SERVICE_TYPE_DINE_IN' },
  { code: 'TAKEOUT', name: 'ORDERS.SERVICE_TYPE_TAKEOUT' },
  { code: 'TAKEOUT_IDEPOS', name: 'ORDERS.SERVICE_TYPE_TAKEOUT_IDEPOS' },
  { code: 'TAKEOUT_IDEFOOD', name: 'ORDERS.SERVICE_TYPE_TAKEOUT_IDEFOOD' },
  { code: 'DELIVERY', name: 'ORDERS.SERVICE_TYPE_DELIVERY' },
  { code: 'PICK_UP_IDEPOS', name: 'ORDERS.SERVICE_TYPE_PICK_UP_IDEPOS' },
  { code: 'PICK_UP_IDEFOOD', name: 'ORDERS.SERVICE_TYPE_PICK_UP_IDEFOOD' },
  { code: 'DINE_IN_IDEPOS', name: 'ORDERS.SERVICE_TYPE_DINE_IN_IDEPOS' },
  { code: 'DINE_IN_IDEFOOD', name: 'ORDERS.SERVICE_TYPE_DINE_IN_IDEFOOD' },
  { code: 'FULL', name: 'ORDERS.SERVICE_TYPE_FULL' },
  { code: 'PARTIAL', name: 'ORDERS.SERVICE_TYPE_PARTIAL' },
]

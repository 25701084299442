import { Injectable } from '@angular/core';
import { IPaginationReq, MerchantStoreInfo, PaginationPerformanceRes } from '../../model/performance.model';
import { Subject } from 'rxjs';
import { PerformanceOrderTypeList } from '../../model/performance-order-type.model';

@Injectable({
  providedIn: 'root'
})
export class PerformanceService {

  constructor() { }

  readonly PERFORMANCE_PAGINATION: string = 'pagination-performance'
  readonly PERFORMANCE_MERCHANT: string = 'performance-merchant-detail'
  
  setPerformancePagination(config: IPaginationReq) {
    localStorage.setItem(this.PERFORMANCE_PAGINATION, JSON.stringify(config))
  }

  getPerformancePaginationConfig() {
    let result: any = localStorage.getItem(this.PERFORMANCE_PAGINATION)
    return JSON.parse(result)
  }

  removeDataMerchantPerformance() {
    localStorage.removeItem(this.PERFORMANCE_MERCHANT)
  }

  setDataMerchantPerformance(merchantDetail: MerchantStoreInfo) {
    if (!merchantDetail) return
    localStorage.setItem(this.PERFORMANCE_MERCHANT, JSON.stringify(merchantDetail))
  }

  getDataMerchantPerformance(): MerchantStoreInfo | undefined {
    let merchant: string | null = localStorage.getItem(this.PERFORMANCE_MERCHANT)
    return JSON.parse(merchant as string)
  }

  getPerformanceOrderType(type: string): string {
    let result: string = ''
    PerformanceOrderTypeList.map((order) => (order.code === type ? (result = order.name) : null))
    return result
  }
}

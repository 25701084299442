import { Area, ReqPagination, SortDirection } from '../../../core/models/common.model'

export interface AdminBusinessDetail {
  country: string
  currency: string
  email: string
  name: string
  template: string
  tier: string
  timezone: string
  id: number
  commission?: number
  ideposType?: TProductService[]
  configService?: IOrderService
  subscriptionJoinDate: number | null
  subscriptionEndDate: number | null
  subscriptionPrice: number | null
  freeMonthSubscriptionDuration: number | null

  // Default store id
  storeId?: number
  status?: number
  statusAccount?: number
  setUpStatus?: number

  // Other random prop
  merchantId?: number

  // Store All indicator
  allStore?: boolean

  // Channel Management
  isChannelManager?: boolean
  channelMerchantIds?: number[]

  addsOn: string[]
  packageType: string
}

export type IOrderService = 'DELIVERY' | 'DINE_IN' | 'TAKEOUT'

export type TProductService = 'IDEPOS' | 'PDA' | 'KIOSK' | string

export interface IProductService {
  code: TProductService
  name: string
}

export const ProductServiceList: IProductService[] = [
  {
    code: 'IDEPOS',
    name: 'BUSINESSES.LABEL_OPTION_IDEPOS',
  },
  {
    code: 'PDA',
    name: 'BUSINESSES.LABEL_OPTION_PDA',
  },
  {
    code: 'KIOSK',
    name: 'BUSINESSES.LABEL_OPTION_KIOSK',
  },
]

export interface AdminBusinessList {
  id: number
  name: string
  status: number
  statusAlias: string
  accessable: boolean
  setUpStatus: number
  setUpTime: string
  approveDate: number
}

export interface BusinessTier extends Area {
  style: object
}

export class BusinessListPaginationConfig extends ReqPagination {
  override pageNumber: number = 0
  override pageSize: number = 10
  override sortDirection: SortDirection = 'DESC'
  maxPageNumber!: number
  totalItem!: number
  selectedBusinessId!: number
}

export interface ResultBusinessListPagination {
  paginationConfig: BusinessListPaginationConfig
  contents: any[]
  isUpdated: boolean
}

export const BUSINESS_ID: string = 'businessId'
export const STORE_ID: string = 'storeId'

export interface Status {
  code: number
  type: string
}

export interface NewBusinessListPageConfig {
  dataQuery?: string
  dateItemEnd?: number
  dateItemStart?: number
  pageNumber: number
  pageSize: number
  setUpStatus?: number
  sortBy?: string
  sortDirection?: SortDirection
  statusAccount?: number
}

export enum MENU_INDEXES {
  // Dashboard
  MENU_DASHBOARD_ID,
  MENU_DASHBOARD_MENU_ID,
  MENU_DASHBOARD_RETAIL_ID,

  // Business
  MENU_BUSINESS_CREATE,
  MENU_BUSINESS_MANAGEMENT,

  // Channel Management
  MENU_CHANNEL_MANAGEMENT,

  // // NPS Report
  // MENU_NPS_REPORT,

  // Cloud Printer
  MENU_CLOUD_PRINTER,

  //Payment Integration
  MENU_PAYMENT_INTEGRATION_ID,
  MENU_PAYMENT_INTEGRATION_KPAY_ID,

  // Log Report
  MENU_LOG_REPORT,
  MENU_LOG_REPORT_IDEFOOD_ID,
  MENU_LOG_REPORT_USER_ID,
  MENU_LOG_REPORT_APP_ID,

  // Delivery (Grab)
  MENU_INTEGRATION_DELIVERY,

  // Cash Drawer
  MENU_SETTING_SA_ID,
  MENU_CASH_DRAWER_ID,

  // Marketing
  MENU_MARKETING_AND_FINANCE_ID,
  MENU_PERFORMANCE_ID,
  MENU_BANK_ACCOUNT_ID,
  MENU_HARDWARE_ID,

  // User Permission
  MENU_USER_PERMISSION_ID,
  MENU_USER_DETAIL_ID,
  MENU_USER_ROLE_ID,

  // Product / Items
  MENU_PRODUCT_ID,
  MENU_FNB_PRODUCT_ID,
  MENU_PRODUCT_CATEGORY_ID,
  MENU_FNB_PRODUCT_CATEGORY_ID,
  MENU_PRODUCT_LIST_ID,
  MENU_PRODUCT_ITEM_LIST_ID,
  MENU_PRODUCT_CUSTOM_ID,
  MENU_PRODUCT_MEDIA_ID,
  MENU_FNB_PRODUCT_MEDIA_ID,

  // Promo / Coupon
  MENU_PROMO_COUPON_ID,
  MENU_PROMO_COUPON_MENU_ID,
  MENU_PROMO_DETAIL_ID,
  MENU_COUPON_DETAIL_ID,

  // Integrations
  MENU_INTEGRATION_ID,
  MENU_INTEGRATION_PAYMENT_ID,
  MENU_INTEGRATION_DELIVERY_ID,

  // Integrations FnB
  MENU_FNB_INTEGRATION_ID,
  MENU_FNB_INTEGRATION_PAYMENT_ID,
  MENU_FNB_INTEGRATION_DELIVERY_ID,
  MENU_FNB_INTEGRATION_IDECONNECT_ID,

  // Order & Customer
  MENU_ORDER_CUSTOMER_RETAIL_ID,
  MENU_ORDER_DETAIL_ID,
  MENU_ORDER_CUSTOMER_MENU_ID,
  MENU_CUSTOMER_DETAIL_ID,

  // Analytics
  MENU_ANALYTIC_ID,
  MENU_FNB_ANALYTIC_ID,
  MENU_ANALYTIC_TRANSACTION_ID,
  MENU_ANALYTIC_PRODUCT_ID,
  MENU_ANALYTIC_ITEM_ID,
  MENU_ANALYTIC_CATEGORY_ID,
  MENU_ANALYTIC_ORDER_ID,
  MENU_ANALYTIC_SHIFT_ID,
  MENU_ANALYTIC_PAYMENT_RECONCILIATION_ID,
  MENU_ANALYTIC_PROMOTIONS_ID,

  // Employee
  MENU_EMPLOYEE_ID,

  // Appearance / Theme / IDEFOOD
  MENU_APPEREANCE_ID,
  MENU_APPEREANCE_FNB_ID,
  MENU_APPEREANCE_THEME_ID,
  MENU_APPEARANCE_SETTING_ID,

  // IDEKIOSK
  MENU_IDEKIOSK_ID,
  MENU_IDEKIOSK_APPEARANCE_ID,
  MENU_IDEKIOSK_SETTINGS_ID,

  // IDEQUEUE
  MENU_IDEQUEUE_ID,
  MENU_IDEQUEUE_APPEARANCE_ID,
  MENU_IDEQUEUE_SETTINGS_ID,
  MENU_IDEQUEUE_QR_ID,

  // Customer Display
  MENU_CUSTOMER_DISPLAY_ID,

  // KDS/EDS
  MENU_KDS_ID,
  MENU_KDS_GENERAL_ID,
  MENU_KDS_DISPLAY_ID,
  MENU_KDS_TICKET_ID,

  // Branch
  MENU_BRANCH_ID,
  MENU_BRANCH_STORE_ID,
  MENU_BRANCH_POS_DEVICE_ID,

  // Table Management
  MENU_TABLE_MANAGEMENT_ID,
  MENU_TABLE_SETUP_ID,
  MENU_TABLE_QR_GENERATOR_ID,

  // Merchant log
  MENU_MERCHANT_LOG,

  // Settings
  MENU_SETTING_ID,
  MENU_FNB_SETTING_ID,
  MENU_SETTING_BUSINESS_ID,
  MENU_SETTING_ORDER_STATUS_ID,
  MENU_SETTING_OPERATION_ID,
  MENU_SETTING_RECEIPT_KITCHEN_TEMPLATE_ID,
  MENU_SETTING_DELIVERY_ID,
  MENU_SETTING_ADDRESS_ID,
  MENU_SETTING_CONTACT_ID,
  MENU_SETTING_TAX_ID,
  MENU_SETTING_DOMAIN_ID,
  MENU_SETTING_ROUNDING_ID,

  // User Permission
  MENU_FNB_USER_PERMISSION_ID,
  MENU_FNB_USER_DETAIL_ID,
  MENU_FNB_USER_ROLE_ID,
}
/** Close ~ Menu Ids */

import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { AppSettings } from '@configs/app.setting'
import { Pages } from '@core/models/common.model'
import { UserContent } from '@core/models/user.model'
import { CommonService } from '@services/common/common.service'
import { CookieService } from 'ngx-cookie'
import { AuthService } from '../auth/auth.service'

@Injectable({
  providedIn: 'root',
})
export class ViewService {
  constructor(
    private _cookieService: CookieService,
    private _authService: AuthService,
    private _router: Router,
    private _commonService: CommonService
  ) {}

  /**
   *
   * @author Abi A.
   * we have a method for saving data in web browser
   * SESSION STORAGE
   * LOCAL STORAGE
   * COOKIES STORAGE
   *
   */

  // readonly Error
  readonly SET_ERROR_PAGE = 'set-error-page'

  // readonly AUTH_INFO = "authInfo";
  readonly PI_UI_BUSINESS = 'pi-ui-business'
  readonly PI_UI_VIEWS = 'pi-ui-views'
  readonly PI_UI_SIDEBAR_NO_EXPAND = 'pi-ui-sidebar-no-expand'
  readonly ACTIVE_PAGE = 'current-active-page'

  // readonly Register Cycle
  readonly REGISTER_FORM = 'register_form'
  readonly REGISTER_PARTICULARS = 'register_particulars'
  readonly REGISTER_STORE_INFO = 'register_store_info'
  readonly REGISTER_STORE_LINKS = 'register_store_links'
  readonly REGISTER_PRODUCTS = 'register_store_products'
  readonly REGISTER_WHATS_NEXT = 'register_store_whats_next'

  // readonly ScreenView
  readonly SCREENVIEW = 'screen_view'

  authInfo: any = null

  // setAuthInfo(authInfo: any) {
  //   this._cookieService.putObject(this.AUTH_INFO, authInfo);
  // }

  // getAuthInfo(): any {
  //   return <any>this._cookieService.getObject(this.AUTH_INFO);
  // }

  /**
   *
   * @sessionStorage
   * USING SESSION STORAGE
   *
   */

  // Session Storage for business

  setActivePage(menuId: number) {
    sessionStorage.setItem(this.ACTIVE_PAGE, JSON.stringify(menuId || 0))
  }

  getActivePage(): number {
    return Number(sessionStorage.getItem(this.ACTIVE_PAGE))
  }

  setBusiness(business: any) {
    sessionStorage.setItem(this.PI_UI_BUSINESS, JSON.stringify(business))
  }

  getBusiness(): any {
    return sessionStorage.getItem(this.PI_UI_BUSINESS)
  }

  removeBusiness() {
    return sessionStorage.removeItem(this.PI_UI_BUSINESS)
  }

  // Session Storage for views
  setViews(views: any) {
    sessionStorage.setItem(this.PI_UI_VIEWS, JSON.stringify(views))
  }

  getViews(): any {
    return sessionStorage.getItem(this.PI_UI_VIEWS)
  }

  removeViews() {
    return sessionStorage.removeItem(this.PI_UI_VIEWS)
  }

  // Session Storage for sidebarExpand or SidebarNoExpand
  setSidebarNoExpand(sidebarNoExpand: any) {
    sessionStorage.setItem(this.PI_UI_SIDEBAR_NO_EXPAND, JSON.stringify(sidebarNoExpand))
  }

  getSidebarNoExpand(): any {
    return sessionStorage.getItem(this.PI_UI_SIDEBAR_NO_EXPAND)
  }

  removeSidebarNoExpand() {
    return sessionStorage.removeItem(this.PI_UI_SIDEBAR_NO_EXPAND)
  }

  // Session Storage for Register Form
  setRegisterForm(registerInfo: any) {
    sessionStorage.setItem(this.REGISTER_FORM, JSON.stringify(registerInfo))
  }

  getRegisterForm(): any {
    return sessionStorage.getItem(this.REGISTER_FORM)
  }

  removeRegisterForm(): any {
    return sessionStorage.removeItem(this.REGISTER_FORM)
  }

  setReloadPage() {
    sessionStorage.setItem('reload', JSON.stringify(true))
  }

  // Session Storage for Register Particulars
  getReloadPage(): boolean {
    let result = false
    if (sessionStorage.getItem('reload')) {
      result = true
    }
    return result
  }

  removeReloadPage() {
    sessionStorage.removeItem('reload')
  }

  setRegisterParticulars(registerInfo: any) {
    sessionStorage.setItem(this.REGISTER_PARTICULARS, JSON.stringify(registerInfo))
  }

  getRegisterParticulars(): any {
    return sessionStorage.getItem(this.REGISTER_PARTICULARS)
  }

  removeRegisterParticulars(): any {
    return sessionStorage.removeItem(this.REGISTER_PARTICULARS)
  }

  // Session Storage for Register Store Info
  setRegisterStoreInfo(registerInfo: any) {
    sessionStorage.setItem(this.REGISTER_STORE_INFO, JSON.stringify(registerInfo))
  }

  getRegisterStoreInfo(): any {
    return sessionStorage.getItem(this.REGISTER_STORE_INFO)
  }

  removeRegisterStoreInfo(): any {
    return sessionStorage.removeItem(this.REGISTER_STORE_INFO)
  }

  // Session Storage for Register Store Links
  setRegisterStoreLinks(registerInfo: any) {
    sessionStorage.setItem(this.REGISTER_STORE_LINKS, JSON.stringify(registerInfo))
  }

  getRegisterStoreLinks(): any {
    return sessionStorage.getItem(this.REGISTER_STORE_LINKS)
  }

  removeRegisterStoreLinks(): any {
    return sessionStorage.removeItem(this.REGISTER_STORE_LINKS)
  }

  // Session Storage for Register Products
  setRegisterProducts(registerInfo: any) {
    sessionStorage.setItem(this.REGISTER_PRODUCTS, JSON.stringify(registerInfo))
  }

  getRegisterProducts(): any {
    return sessionStorage.getItem(this.REGISTER_PRODUCTS)
  }

  removeRegisterProducts(): any {
    return sessionStorage.removeItem(this.REGISTER_PRODUCTS)
  }

  // Session Storage for Register Whats Next
  setRegisterWhatsNext(registerInfo: any) {
    sessionStorage.setItem(this.REGISTER_WHATS_NEXT, JSON.stringify(registerInfo))
  }

  getRegisterWhatsNext(): any {
    return sessionStorage.getItem(this.REGISTER_WHATS_NEXT)
  }

  removeRegisterWhatsNext(): any {
    return sessionStorage.removeItem(this.REGISTER_WHATS_NEXT)
  }

  /**
   *
   * @localStorage
   * USING LOCAL STORAGE
   *
   */

  setErrorPage(isError: boolean) {
    localStorage.setItem(this.SET_ERROR_PAGE, JSON.stringify(isError))
  }

  getErrorPage(): boolean {
    let result = false
    let setErrorPage: any = localStorage.getItem(this.SET_ERROR_PAGE)
    setErrorPage = JSON.parse(setErrorPage)
    if (typeof setErrorPage == 'boolean') result = setErrorPage
    // return result;
    return false
  }

  setScreenView(screenView: string) {
    localStorage.setItem(this.SCREENVIEW, screenView)
  }

  getScreenView() {
    return localStorage.getItem(this.SCREENVIEW)
  }

  setTemplate(templateCode: string) {
    localStorage.setItem(AppSettings.TEMPLATE, JSON.stringify(templateCode))
  }

  getTemplate(): string {
    let result: any = ''
    result = localStorage.getItem(AppSettings.TEMPLATE)
    result = JSON.parse(result)
    return result
  }

  /**
   *
   * @cookieService
   * USING COOKIES STORAGE
   *
   */

  handleAuthKeyData(user: UserContent = new UserContent()) {
    const authLevel = user.role.authLevel
    this._authService.setAuthLevel(authLevel)
    if (authLevel === 'BUSINESS') {
      this._router.navigate([this._commonService.getDashboardUrl()])
    }
  }

  setActivePageName(menu: string) {
    sessionStorage.setItem('current-active-page-name', JSON.stringify(menu))
  }

  getActivePageName(): string | null {
    if (sessionStorage.getItem('current-active-page-name') !== null) {
      return JSON.parse(sessionStorage.getItem('current-active-page-name') || '')
    } else {
      return null
    }
  }
}

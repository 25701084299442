import { NavigationExtras } from '@angular/router'

export class SortModel {
  sorted?: boolean
  unsorted?: boolean
}

export class PageableModel {
  sort?: SortModel
  pageSize?: number
  pageNumber?: number
  offset?: number
  paged?: boolean
  unpaged?: boolean
}

export class Authorities {
  authority?: string
}

export class ResponseModel<T> {
  timestamp?: number
  code?: string
  status?: number
  data?: T
  page?: PageModel
  error?: string
}

export class PageModel {
  pageable?: PageableModel
  first?: boolean
  last?: boolean
  number?: number
  numberOfElements?: number
  size?: number
  sort?: SortModel
  totalPages?: number
  totalElements?: number
}

export class MetaDataModel {
  status?: number
  active?: boolean
}

export class BaseModel extends MetaDataModel {
  id?: number
}

export class BaseSidModel extends MetaDataModel {
  id?: string
}

export class TableHeader {
  name?: string
  sortable?: boolean
}

export class TableAction {
  name?: string
  url?: string
}

export class TableItem {
  name?: string
  img?: string
  inputType?: string
  subStatus?: string
  actions?: TableAction[]
}

export class ErrorInputAlt {
  show!: boolean
  msg!: string
}

export class Country {
  id!: string
  name!: string
}

export class ImageType {
  url200!: string
  url300!: string
  url400!: string
  thumbnailUrl!: string
  value!: string
  uploadId!: number
  urls!: UrlImage
}

export class ImageError {
  maximumSize!: string
  minimumWidth!: string
  minimumHeight!: string
  maximumWidth!: string
  maximumHeight!: string
}

export class UploadImage {
  id!: number
  file: any
  prefix!: string
  status!: number
  error!: string
  message!: string
  data!: ImageType
  pathUrl!: string
  isVideo?: boolean
}

export class UrlImage {
  100?: string
  full!: string
}

export class PaginationSort {
  sorted!: boolean
  unsorted!: boolean
  empty!: boolean
}

export class PaginationPageable {
  sort!: PaginationSort
  offset!: number
  pageNumber!: number
  pageSize!: number
  paged!: boolean
  unpaged!: boolean
}

export class PaginationPage {
  pageable!: PaginationPageable
  first!: boolean
  last!: boolean
  number!: number
  numberOfElements!: number
  size!: number
  sort!: PaginationSort
  totalPages!: number
  totalElements!: number
}

/**
 * Extended:
 */
export declare interface Area {
  code: string
  name: any
  children?: null
  primary?: boolean
  alias?: string
}

export declare interface AreaList {
  status: number
  code: string
  data: Area[]
}

export class ToastPosition {
  topLeft: boolean = false
  topCenter: boolean = false
  topRight: boolean = false
  middleLeft: boolean = false
  middleCenter: boolean = false
  middleRight: boolean = false
  bottomLeft: boolean = false
  bottomCenter: boolean = false
  bottomRight: boolean = false
}

export class Toast {
  headless?: boolean = false
  class?: string
  title?: string
  subtitle?: string
  msg?: string
  position?: ToastPosition = new ToastPosition()
  addConfirmButton?: boolean
  addCancelButton?: boolean
  closeLess?: boolean
  imgUrl!: string
}

export type Icon = 'copy' | 'duplicate' | 'remove' | 'share' | 'analytic' | 'import' | 'hint'

export class TableEvent {
  id!: string
  type!: Icon
}

export class CountryResponse {
  code!: string
  status!: number
  data!: Country[]
  page!: PaginationPage
}

export class ModalConfig {
  class!: string
  title!: string
  msg!: string
  footer!: string
  buttonLess: boolean = false
  contentLess: boolean = false
  fitContent: boolean = false
  buttonConfirm!: string
  buttonCancel!: string
  mobileMode: boolean = false
  isModalClose: boolean = false
  hideCancelBtn: boolean = false
  hideConfirmBtn: boolean = false
  disableConfirmBtn: boolean = false
  disableCancelBtn: boolean = false
  contentTransparent: boolean = false
  hideCloseBtn: boolean = false
  customInput: string = ''
  position: 'top' | 'center' | 'bottom' = 'center'
  wrapperStyle?: { [klass: string]: any }
  modalContainerStyle?: { [klass: string]: any }
  dialogStyle?: { [klass: string]: any }
  isBtnConfirmGradient: boolean = false
  isBtnCancelGradient: boolean = false
  msgStyle?: { [klass: string]: any }
  btnContainerStyle?: { [klass: string]: any }
}

export class ModalResponse {
  confirm: boolean = false
  input!: string
}

export const CLICK_HEADER_MY_ORDER = 'clickHeaderOrder'
export const CLICK_HEADER_CART = 'clickHeaderCart'

export const PASSWORD_PATTERN = new RegExp('^(?=[^A-Z]*[A-Z])(?=\\D*\\d)[A-Za-z\\d-!$%@#£€_+=.^*?&]{8,}$')
export const URL_PATTERN = new RegExp('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?')

export class TopBarAccessIcon {
  isAvatar?: boolean
  topBarTitle: string = ''
  isMenu?: boolean
  addIcon?: string
  importIcon?: string
  isDeleteIcon?: any
  isBackIcon?: boolean
  isPreviewIcon?: boolean
  isAnalyticIcon?: boolean
  isDuplicateIcon?: boolean
  addPreview?: string
  addAnalytic?: string
  addDuplicate?: string
  isLanguage?: boolean
  storeIcon?: boolean
}

export class PaginationData {
  content!: any[]
  pageable!: PaginationPageable
  last!: boolean
  totalPages!: number
  totalElements!: number
  sort!: PaginationSort
  first!: boolean
  numberOfElements!: number
  size!: number
  number!: number
  empty!: boolean
}

export class Pagination {
  code!: string
  status!: number
  data!: PaginationData
}

export class ReqPagination {
  dataQuery!: string
  pageNumber!: number
  pageSize!: number
  sortBy!: string
  sortDirection!: SortDirection
  status!: number
  dateStart?: string
  dateEnd?: string
  statusOrder?: number
  type?: string
  storeId?: number
  setupStatus?: number
}

export class HttpResponseErrorPass {
  status?: number
  message?: string
  redirect: boolean = false
  redirectUrl?: string
}

export class ItemListPassValue {
  id!: number
  isChecked?: boolean
}

export class ErrorCustom {
  title!: string
  subtitle!: string
  btnText!: string
  subtitleRedirectUrl?: string
  subtitleRedirectContent?: string
  urlRedirect?: string
  urlImg?: string
}

export type Size = 'small' | 'normal' | 'large'

export type RadioStyle = 'normal' | 'checklist'

export type Pages =
  | ''
  | string
  | null
  | 'dashboard'
  | 'links'
  | 'product'
  | 'integration'
  | 'orders'
  | 'promotions'
  | 'content'
  | 'settings'

export class JobQueueStatusHistory {
  status!: number
  statusAt!: number
}

export class JobQueueCheck {
  progress!: number
  status!: number
  error!: boolean
  statusHistory!: JobQueueStatusHistory[]
  jobResultCanBeAccess!: boolean
}

export class DropdownSearch {
  key!: string | number
  checked!: boolean
}

export type PaginationMode =
  | 'search'
  | 'filter'
  | 'filter-type'
  | 'filter-status'
  | 'page'
  | 'date-start'
  | 'date-end'
  | 'page-size'
  | 'filter-setup-status'
export const PAGINATION_MODE_SEARCH: PaginationMode = 'search'
export const PAGINATION_MODE_FILTER: PaginationMode = 'filter'
export const PAGINATION_MODE_FILTER_TYPE: PaginationMode = 'filter-type'
export const PAGINATION_MODE_FILTER_STATUS: PaginationMode = 'filter-status'
export const PAGINATION_MODE_PAGE: PaginationMode = 'page'
export const PAGINATION_MODE_SIZE: PaginationMode = 'page-size'
export const PAGINATION_MODE_DATE_START: PaginationMode = 'date-start'
export const PAGINATION_MODE_DATE_END: PaginationMode = 'date-end'

export type SortDirection = 'DESC' | 'ASC'

export const NO_DATA = 'no data'

export class UploadFileResponse {
  uploadId!: number
  uploadName!: string
  urls!: { 80: string; 150: string; 320: string; 600: string; full: string }
}

export type Severity = 'warning' | 'success' | 'danger' | 'info'

export class Alert {
  visible!: boolean
  message!: string
  duration!: number
  severity!: Severity
}

export interface HttpResponseData<T> {
  code: string
  status: number
  data: T
}

// Data Country
export const dataCountry: any[] = [
  {
    label: 'Indonesia',
    codeNumber: '+62',
    country: 'ID',
  },
  // {
  //   label: 'Singapore',
  //   codeNumber: '+65',
  //   country: 'SG',
  // }
  // {
  //   label: 'Afghanistan',
  //   codeNumber: '+93',
  //   country: 'AF',
  // },
  // {
  //   label: 'Aland Islands',
  //   codeNumber: '+358',
  //   country: 'AX',
  // },
  // {
  //   label: 'Albania',
  //   codeNumber: '+355',
  //   country: 'AL',
  // },
  // {
  //   label: 'Algeria',
  //   codeNumber: '+213',
  //   country: 'DZ',
  // },
  // {
  //   label: 'American Samoa',
  //   codeNumber: '+1 684',
  //   country: 'AS',
  // },
  // {
  //   label: 'Andorra',
  //   codeNumber: '+376',
  //   country: 'AD',
  // },
  // {
  //   label: 'Angola',
  //   codeNumber: '+244',
  //   country: 'AO',
  // },
  // {
  //   label: 'Anguilla',
  //   codeNumber: '+1 264',
  //   country: 'AI',
  // },
  // {
  //   label: 'Antarctica',
  //   codeNumber: '+672',
  //   country: 'AQ',
  // },
  // {
  //   label: 'Antigua and Barbuda',
  //   codeNumber: '+1268',
  //   country: 'AG',
  // },
  // {
  //   label: 'Argentina',
  //   codeNumber: '+54',
  //   country: 'AR',
  // },
  // {
  //   label: 'Armenia',
  //   codeNumber: '+374',
  //   country: 'AM',
  // },
  // {
  //   label: 'Aruba',
  //   codeNumber: '+297',
  //   country: 'AW',
  // },
  // {
  //   label: 'Australia',
  //   codeNumber: '+61',
  //   country: 'AU',
  // },
  // {
  //   label: 'Austria',
  //   codeNumber: '+43',
  //   country: 'AT',
  // },
  // {
  //   label: 'Azerbaijan',
  //   codeNumber: '+994',
  //   country: 'AZ',
  // },
  // {
  //   label: 'Bahamas',
  //   codeNumber: '+1 242',
  //   country: 'BS',
  // },
  // {
  //   label: 'Bahrain',
  //   codeNumber: '+973',
  //   country: 'BH',
  // },
  // {
  //   label: 'Bangladesh',
  //   codeNumber: '+880',
  //   country: 'BD',
  // },
  // {
  //   label: 'Barbados',
  //   codeNumber: '+1 246',
  //   country: 'BB',
  // },
  // {
  //   label: 'Belarus',
  //   codeNumber: '+375',
  //   country: 'BY',
  // },
  // {
  //   label: 'Belgium',
  //   codeNumber: '+32',
  //   country: 'BE',
  // },
  // {
  //   label: 'Belize',
  //   codeNumber: '+501',
  //   country: 'BZ',
  // },
  // {
  //   label: 'Benin',
  //   codeNumber: '+229',
  //   country: 'BJ',
  // },
  // {
  //   label: 'Bermuda',
  //   codeNumber: '+1 441',
  //   country: 'BM',
  // },
  // {
  //   label: 'Bhutan',
  //   codeNumber: '+975',
  //   country: 'BT',
  // },
  // {
  //   label: 'Bolivia, Plurinational State of',
  //   codeNumber: '+591',
  //   country: 'BO',
  // },
  // {
  //   label: 'Bosnia and Herzegovina',
  //   codeNumber: '+387',
  //   country: 'BA',
  // },
  // {
  //   label: 'Botswana',
  //   codeNumber: '+267',
  //   country: 'BW',
  // },
  // {
  //   label: 'Brazil',
  //   codeNumber: '+55',
  //   country: 'BR',
  // },
  // {
  //   label: 'British Indian Ocean Territory',
  //   codeNumber: '+246',
  //   country: 'IO',
  // },
  // {
  //   label: 'Brunei Darussalam',
  //   codeNumber: '+673',
  //   country: 'BN',
  // },
  // {
  //   label: 'Bulgaria',
  //   codeNumber: '+359',
  //   country: 'BG',
  // },
  // {
  //   label: 'Burkina Faso',
  //   codeNumber: '+226',
  //   country: 'BF',
  // },
  // {
  //   label: 'Burundi',
  //   codeNumber: '+257',
  //   country: 'BI',
  // },
  // ,{
  //   label: 'Cambodia',
  //   codeNumber: '+855',
  //   country: 'KH',
  // },
  // {
  //   label: 'Cameroon',
  //   codeNumber: '+237',
  //   country: 'CM',
  // },
  // {
  //   label: 'Canada',
  //   codeNumber: '+1',
  //   country: 'CA',
  // },
  // {
  //   label: 'Cape Verde',
  //   codeNumber: '+238',
  //   country: 'CV',
  // },
  // {
  //   label: 'Cayman Islands',
  //   codeNumber: '+ 345',
  //   country: 'KY',
  // },
  // {
  //   label: 'Central African Republic',
  //   codeNumber: '+236',
  //   country: 'CF',
  // },
  // {
  //   label: 'Chad',
  //   codeNumber: '+235',
  //   country: 'TD',
  // },
  // {
  //   label: 'Chile',
  //   codeNumber: '+56',
  //   country: 'CL',
  // },
  // {
  //   label: 'China',
  //   codeNumber: '+86',
  //   country: 'CN',
  // },
  // {
  //   label: 'Christmas Island',
  //   codeNumber: '+61',
  //   country: 'CX',
  // },
  // {
  //   label: 'Cocos (Keeling) Islands',
  //   codeNumber: '+61',
  //   country: 'CC',
  // },
  // {
  //   label: 'Colombia',
  //   codeNumber: '+57',
  //   country: 'CO',
  // },
  // {
  //   label: 'Comoros',
  //   codeNumber: '+269',
  //   country: 'KM',
  // },
  // {
  //   label: 'Congo',
  //   codeNumber: '+242',
  //   country: 'CG',
  // },
  // {
  //   label: 'Congo, The Democratic Republic of the Congo',
  //   codeNumber: '+243',
  //   country: 'CD',
  // },
  // {
  //   label: 'Cook Islands',
  //   codeNumber: '+682',
  //   country: 'CK',
  // },
  // {
  //   label: 'Costa Rica',
  //   codeNumber: '+506',
  //   country: 'CR',
  // },
  // {
  //   label: "Cote d'Ivoire",
  //   codeNumber: '+225',
  //   country: 'CI',
  // },
  // {
  //   label: 'Croatia',
  //   codeNumber: '+385',
  //   country: 'HR',
  // },
  // {
  //   label: 'Cuba',
  //   codeNumber: '+53',
  //   country: 'CU',
  // },
  // {
  //   label: 'Cyprus',
  //   codeNumber: '+357',
  //   country: 'CY',
  // },
  // {
  //   label: 'Czech Republic',
  //   codeNumber: '+420',
  //   country: 'CZ',
  // },
  // {
  //   label: 'Denmark',
  //   codeNumber: '+45',
  //   country: 'DK',
  // },
  // {
  //   label: 'Djibouti',
  //   codeNumber: '+253',
  //   country: 'DJ',
  // },
  // {
  //   label: 'Dominica',
  //   codeNumber: '+1 767',
  //   country: 'DM',
  // },
  // {
  //   label: 'Dominican Republic',
  //   codeNumber: '+1 849',
  //   country: 'DO',
  // },
  // {
  //   label: 'Ecuador',
  //   codeNumber: '+593',
  //   country: 'EC',
  // },
  // {
  //   label: 'Egypt',
  //   codeNumber: '+20',
  //   country: 'EG',
  // },
  // {
  //   label: 'El Salvador',
  //   codeNumber: '+503',
  //   country: 'SV',
  // },
  // {
  //   label: 'Equatorial Guinea',
  //   codeNumber: '+240',
  //   country: 'GQ',
  // },
  // {
  //   label: 'Eritrea',
  //   codeNumber: '+291',
  //   country: 'ER',
  // },
  // {
  //   label: 'Estonia',
  //   codeNumber: '+372',
  //   country: 'EE',
  // },
  // {
  //   label: 'Ethiopia',
  //   codeNumber: '+251',
  //   country: 'ET',
  // },
  // {
  //   label: 'Falkland Islands (Malvinas)',
  //   codeNumber: '+500',
  //   country: 'FK',
  // },
  // {
  //   label: 'Faroe Islands',
  //   codeNumber: '+298',
  //   country: 'FO',
  // },
  // {
  //   label: 'Fiji',
  //   codeNumber: '+679',
  //   country: 'FJ',
  // },
  // {
  //   label: 'Finland',
  //   codeNumber: '+358',
  //   country: 'FI',
  // },
  // {
  //   label: 'France',
  //   codeNumber: '+33',
  //   country: 'FR',
  // },
  // {
  //   label: 'French Guiana',
  //   codeNumber: '+594',
  //   country: 'GF',
  // },
  // {
  //   label: 'French Polynesia',
  //   codeNumber: '+689',
  //   country: 'PF',
  // },
  // {
  //   label: 'Gabon',
  //   codeNumber: '+241',
  //   country: 'GA',
  // },
  // {
  //   label: 'Gambia',
  //   codeNumber: '+220',
  //   country: 'GM',
  // },
  // {
  //   label: 'Georgia',
  //   codeNumber: '+995',
  //   country: 'GE',
  // },
  // {
  //   label: 'Germany',
  //   codeNumber: '+49',
  //   country: 'DE',
  // },
  // {
  //   label: 'Ghana',
  //   codeNumber: '+233',
  //   country: 'GH',
  // },
  // {
  //   label: 'Gibraltar',
  //   codeNumber: '+350',
  //   country: 'GI',
  // },
  // {
  //   label: 'Greece',
  //   codeNumber: '+30',
  //   country: 'GR',
  // },
  // {
  //   label: 'Greenland',
  //   codeNumber: '+299',
  //   country: 'GL',
  // },
  // {
  //   label: 'Grenada',
  //   codeNumber: '+1 473',
  //   country: 'GD',
  // },
  // {
  //   label: 'Guadeloupe',
  //   codeNumber: '+590',
  //   country: 'GP',
  // },
  // {
  //   label: 'Guam',
  //   codeNumber: '+1 671',
  //   country: 'GU',
  // },
  // {
  //   label: 'Guatemala',
  //   codeNumber: '+502',
  //   country: 'GT',
  // },
  // {
  //   label: 'Guernsey',
  //   codeNumber: '+44',
  //   country: 'GG',
  // },
  // {
  //   label: 'Guinea',
  //   codeNumber: '+224',
  //   country: 'GN',
  // },
  // {
  //   label: 'Guinea-Bissau',
  //   codeNumber: '+245',
  //   country: 'GW',
  // },
  // {
  //   label: 'Guyana',
  //   codeNumber: '+595',
  //   country: 'GY',
  // },
  // {
  //   label: 'Haiti',
  //   codeNumber: '+509',
  //   country: 'HT',
  // },
  // {
  //   label: 'Holy See (Vatican City State)',
  //   codeNumber: '+379',
  //   country: 'VA',
  // },
  // {
  //   label: 'Honduras',
  //   codeNumber: '+504',
  //   country: 'HN',
  // },
  // {
  //   label: 'Hong Kong',
  //   codeNumber: '+852',
  //   country: 'HK',
  // },
  // {
  //   label: 'Hungary',
  //   codeNumber: '+36',
  //   country: 'HU',
  // },
  // {
  //   label: 'Iceland',
  //   codeNumber: '+354',
  //   country: 'IS',
  // },
  // {
  //   label: 'India',
  //   codeNumber: '+91',
  //   country: 'IN',
  // },
  // {
  //   label: 'Iran, Islamic Republic of Persian Gulf',
  //   codeNumber: '+98',
  //   country: 'IR',
  // },
  // {
  //   label: 'Iraq',
  //   codeNumber: '+964',
  //   country: 'IQ',
  // },
  // {
  //   label: 'Ireland',
  //   codeNumber: '+353',
  //   country: 'IE',
  // },
  // {
  //   label: 'Isle of Man',
  //   codeNumber: '+44',
  //   country: 'IM',
  // },
  // {
  //   label: 'Israel',
  //   codeNumber: '+972',
  //   country: 'IL',
  // },
  // {
  //   label: 'Italy',
  //   codeNumber: '+39',
  //   country: 'IT',
  // },
  // {
  //   label: 'Jamaica',
  //   codeNumber: '+1 876',
  //   country: 'JM',
  // },
  // {
  //   label: 'Japan',
  //   codeNumber: '+81',
  //   country: 'JP',
  // },
  // {
  //   label: 'Jersey',
  //   codeNumber: '+44',
  //   country: 'JE',
  // },
  // {
  //   label: 'Jordan',
  //   codeNumber: '+962',
  //   country: 'JO',
  // },
  // {
  //   label: 'Kazakhstan',
  //   codeNumber: '+7 7',
  //   country: 'KZ',
  // },
  // {
  //   label: 'Kenya',
  //   codeNumber: '+254',
  //   country: 'KE',
  // },
  // {
  //   label: 'Kiribati',
  //   codeNumber: '+686',
  //   country: 'KI',
  // },
  // {
  //   label: "Korea, Democratic People's Republic of Korea",
  //   codeNumber: '+850',
  //   country: 'KP',
  // },
  // {
  //   label: 'Korea, Republic of South Korea',
  //   codeNumber: '+82',
  //   country: 'KR',
  // },
  // {
  //   label: 'Kuwait',
  //   codeNumber: '+965',
  //   country: 'KW',
  // },
  // {
  //   label: 'Kyrgyzstan',
  //   codeNumber: '+996',
  //   country: 'KG',
  // },
  // {
  //   label: 'Laos',
  //   codeNumber: '+856',
  //   country: 'LA',
  // },
  // {
  //   label: 'Latvia',
  //   codeNumber: '+371',
  //   country: 'LV',
  // },
  // {
  //   label: 'Lebanon',
  //   codeNumber: '+961',
  //   country: 'LB',
  // },
  // {
  //   label: 'Lesotho',
  //   codeNumber: '+266',
  //   country: 'LS',
  // },
  // {
  //   label: 'Liberia',
  //   codeNumber: '+231',
  //   country: 'LR',
  // },
  // {
  //   label: 'Libyan Arab Jamahiriya',
  //   codeNumber: '+218',
  //   country: 'LY',
  // },
  // {
  //   label: 'Liechtenstein',
  //   codeNumber: '+423',
  //   country: 'LI',
  // },
  // {
  //   label: 'Lithuania',
  //   codeNumber: '+370',
  //   country: 'LT',
  // },
  // {
  //   label: 'Luxembourg',
  //   codeNumber: '+352',
  //   country: 'LU',
  // },
  // {
  //   label: 'Macao',
  //   codeNumber: '+853',
  //   country: 'MO',
  // },
  // {
  //   label: 'Macedonia',
  //   codeNumber: '+389',
  //   country: 'MK',
  // },
  // {
  //   label: 'Madagascar',
  //   codeNumber: '+261',
  //   country: 'MG',
  // },
  // {
  //   label: 'Malawi',
  //   codeNumber: '+265',
  //   country: 'MW',
  // },
  // {
  //   label: 'Malaysia',
  //   codeNumber: '+60',
  //   country: 'MY',
  // },
  // {
  //   label: 'Maldives',
  //   codeNumber: '+960',
  //   country: 'MV',
  // },
  // {
  //   label: 'Mali',
  //   codeNumber: '+223',
  //   country: 'ML',
  // },
  // {
  //   label: 'Malta',
  //   codeNumber: '+356',
  //   country: 'MT',
  // },
  // {
  //   label: 'Marshall Islands',
  //   codeNumber: '+692',
  //   country: 'MH',
  // },
  // {
  //   label: 'Martinique',
  //   codeNumber: '+596',
  //   country: 'MQ',
  // },
  // {
  //   label: 'Mauritania',
  //   codeNumber: '+222',
  //   country: 'MR',
  // },
  // {
  //   label: 'Mauritius',
  //   codeNumber: '+230',
  //   country: 'MU',
  // },
  // {
  //   label: 'Mayotte',
  //   codeNumber: '+262',
  //   country: 'YT',
  // },
  // {
  //   label: 'Mexico',
  //   codeNumber: '+52',
  //   country: 'MX',
  // },
  // {
  //   label: 'Micronesia, Federated States of Micronesia',
  //   codeNumber: '+691',
  //   country: 'FM',
  // },
  // {
  //   label: 'Moldova',
  //   codeNumber: '+373',
  //   country: 'MD',
  // },
  // {
  //   label: 'Monaco',
  //   codeNumber: '+377',
  //   country: 'MC',
  // },
  // {
  //   label: 'Mongolia',
  //   codeNumber: '+976',
  //   country: 'MN',
  // },
  // {
  //   label: 'Montenegro',
  //   codeNumber: '+382',
  //   country: 'ME',
  // },
  // {
  //   label: 'Montserrat',
  //   codeNumber: '+1664',
  //   country: 'MS',
  // },
  // {
  //   label: 'Morocco',
  //   codeNumber: '+212',
  //   country: 'MA',
  // },
  // {
  //   label: 'Mozambique',
  //   codeNumber: '+258',
  //   country: 'MZ',
  // },
  // {
  //   label: 'Myanmar',
  //   codeNumber: '+95',
  //   country: 'MM',
  // },
  // {
  //   label: 'Namibia',
  //   codeNumber: '+264',
  //   country: 'NA',
  // },
  // {
  //   label: 'Nauru',
  //   codeNumber: '+674',
  //   country: 'NR',
  // },
  // {
  //   label: 'Nepal',
  //   codeNumber: '+977',
  //   country: 'NP',
  // },
  // {
  //   label: 'Netherlands',
  //   codeNumber: '+31',
  //   country: 'NL',
  // },
  // {
  //   label: 'Netherlands Antilles',
  //   codeNumber: '+599',
  //   country: 'AN',
  // },
  // {
  //   label: 'New Caledonia',
  //   codeNumber: '+687',
  //   country: 'NC',
  // },
  // {
  //   label: 'New Zealand',
  //   codeNumber: '+64',
  //   country: 'NZ',
  // },
  // {
  //   label: 'Nicaragua',
  //   codeNumber: '+505',
  //   country: 'NI',
  // },
  // {
  //   label: 'Niger',
  //   codeNumber: '+227',
  //   country: 'NE',
  // },
  // {
  //   label: 'Nigeria',
  //   codeNumber: '+234',
  //   country: 'NG',
  // },
  // {
  //   label: 'Niue',
  //   codeNumber: '+683',
  //   country: 'NU',
  // },
  // {
  //   label: 'Norfolk Island',
  //   codeNumber: '+672',
  //   country: 'NF',
  // },
  // {
  //   label: 'Northern Mariana Islands',
  //   codeNumber: '+1 670',
  //   country: 'MP',
  // },
  // {
  //   label: 'Norway',
  //   codeNumber: '+47',
  //   country: 'NO',
  // },
  // {
  //   label: 'Oman',
  //   codeNumber: '+968',
  //   country: 'OM',
  // },
  // {
  //   label: 'Pakistan',
  //   codeNumber: '+92',
  //   country: 'PK',
  // },
  // {
  //   label: 'Palau',
  //   codeNumber: '+680',
  //   country: 'PW',
  // },
  // {
  //   label: 'Palestinian Territory, Occupied',
  //   codeNumber: '+970',
  //   country: 'PS',
  // },
  // {
  //   label: 'Panama',
  //   codeNumber: '+507',
  //   country: 'PA',
  // },
  // {
  //   label: 'Papua New Guinea',
  //   codeNumber: '+675',
  //   country: 'PG',
  // },
  // {
  //   label: 'Paraguay',
  //   codeNumber: '+595',
  //   country: 'PY',
  // },
  // {
  //   label: 'Peru',
  //   codeNumber: '+51',
  //   country: 'PE',
  // },
  // {
  //   label: 'Philippines',
  //   codeNumber: '+63',
  //   country: 'PH',
  // },
  // {
  //   label: 'Pitcairn',
  //   codeNumber: '+872',
  //   country: 'PN',
  // },
  // {
  //   label: 'Poland',
  //   codeNumber: '+48',
  //   country: 'PL',
  // },
  // {
  //   label: 'Portugal',
  //   codeNumber: '+351',
  //   country: 'PT',
  // },
  // {
  //   label: 'Puerto Rico',
  //   codeNumber: '+1 939',
  //   country: 'PR',
  // },
  // {
  //   label: 'Qatar',
  //   codeNumber: '+974',
  //   country: 'QA',
  // },
  // {
  //   label: 'Romania',
  //   codeNumber: '+40',
  //   country: 'RO',
  // },
  // {
  //   label: 'Russia',
  //   codeNumber: '+7',
  //   country: 'RU',
  // },
  // {
  //   label: 'Rwanda',
  //   codeNumber: '+250',
  //   country: 'RW',
  // },
  // {
  //   label: 'Reunion',
  //   codeNumber: '+262',
  //   country: 'RE',
  // },
  // {
  //   label: 'Saint Barthelemy',
  //   codeNumber: '+590',
  //   country: 'BL',
  // },
  // {
  //   label: 'Saint Helena, Ascension and Tristan Da Cunha',
  //   codeNumber: '+290',
  //   country: 'SH',
  // },
  // {
  //   label: 'Saint Kitts and Nevis',
  //   codeNumber: '+1 869',
  //   country: 'KN',
  // },
  // {
  //   label: 'Saint Lucia',
  //   codeNumber: '+1 758',
  //   country: 'LC',
  // },
  // {
  //   label: 'Saint Martin',
  //   codeNumber: '+590',
  //   country: 'MF',
  // },
  // {
  //   label: 'Saint Pierre and Miquelon',
  //   codeNumber: '+508',
  //   country: 'PM',
  // },
  // {
  //   label: 'Saint Vincent and the Grenadines',
  //   codeNumber: '+1 784',
  //   country: 'VC',
  // },
  // {
  //   label: 'Samoa',
  //   codeNumber: '+685',
  //   country: 'WS',
  // },
  // {
  //   label: 'San Marino',
  //   codeNumber: '+378',
  //   country: 'SM',
  // },
  // {
  //   label: 'Sao Tome and Principe',
  //   codeNumber: '+239',
  //   country: 'ST',
  // },
  // {
  //   label: 'Saudi Arabia',
  //   codeNumber: '+966',
  //   country: 'SA',
  // },
  // {
  //   label: 'Senegal',
  //   codeNumber: '+221',
  //   country: 'SN',
  // },
  // {
  //   label: 'Serbia',
  //   codeNumber: '+381',
  //   country: 'RS',
  // },
  // {
  //   label: 'Seychelles',
  //   codeNumber: '+248',
  //   country: 'SC',
  // },
  // {
  //   label: 'Sierra Leone',
  //   codeNumber: '+232',
  //   country: 'SL',
  // },
  // {
  //   label: 'Slovakia',
  //   codeNumber: '+421',
  //   country: 'SK',
  // },
  // {
  //   label: 'Slovenia',
  //   codeNumber: '+386',
  //   country: 'SI',
  // },
  // {
  //   label: 'Solomon Islands',
  //   codeNumber: '+677',
  //   country: 'SB',
  // },
  // {
  //   label: 'Somalia',
  //   codeNumber: '+252',
  //   country: 'SO',
  // },
  // {
  //   label: 'South Africa',
  //   codeNumber: '+27',
  //   country: 'ZA',
  // },
  // {
  //   label: 'South Georgia and the South Sandwich Islands',
  //   codeNumber: '+500',
  //   country: 'GS',
  // },
  // {
  //   label: 'Spain',
  //   codeNumber: '+34',
  //   country: 'ES',
  // },
  // {
  //   label: 'Sri Lanka',
  //   codeNumber: '+94',
  //   country: 'LK',
  // },
  // {
  //   label: 'Sudan',
  //   codeNumber: '+249',
  //   country: 'SD',
  // },
  // {
  //   label: 'Suriname',
  //   codeNumber: '+597',
  //   country: 'SR',
  // },
  // {
  //   label: 'Svalbard and Jan Mayen',
  //   codeNumber: '+47',
  //   country: 'SJ',
  // },
  // {
  //   label: 'Swaziland',
  //   codeNumber: '+268',
  //   country: 'SZ',
  // },
  // {
  //   label: 'Sweden',
  //   codeNumber: '+46',
  //   country: 'SE',
  // },
  // {
  //   label: 'Switzerland',
  //   codeNumber: '+41',
  //   country: 'CH',
  // },
  // {
  //   label: 'Syrian Arab Republic',
  //   codeNumber: '+963',
  //   country: 'SY',
  // },
  // {
  //   label: 'Taiwan',
  //   codeNumber: '+886',
  //   country: 'TW',
  // },
  // {
  //   label: 'Tajikistan',
  //   codeNumber: '+992',
  //   country: 'TJ',
  // },
  // {
  //   label: 'Tanzania, United Republic of Tanzania',
  //   codeNumber: '+255',
  //   country: 'TZ',
  // },
  // {
  //   label: 'Thailand',
  //   codeNumber: '+66',
  //   country: 'TH',
  // },
  // {
  //   label: 'Timor-Leste',
  //   codeNumber: '+670',
  //   country: 'TL',
  // },
  // {
  //   label: 'Togo',
  //   codeNumber: '+228',
  //   country: 'TG',
  // },
  // {
  //   label: 'Tokelau',
  //   codeNumber: '+690',
  //   country: 'TK',
  // },
  // {
  //   label: 'Tonga',
  //   codeNumber: '+676',
  //   country: 'TO',
  // },
  // {
  //   label: 'Trinidad and Tobago',
  //   codeNumber: '+1 868',
  //   country: 'TT',
  // },
  // {
  //   label: 'Tunisia',
  //   codeNumber: '+216',
  //   country: 'TN',
  // },
  // {
  //   label: 'Turkey',
  //   codeNumber: '+90',
  //   country: 'TR',
  // },
  // {
  //   label: 'Turkmenistan',
  //   codeNumber: '+993',
  //   country: 'TM',
  // },
  // {
  //   label: 'Turks and Caicos Islands',
  //   codeNumber: '+1 649',
  //   country: 'TC',
  // },
  // {
  //   label: 'Tuvalu',
  //   codeNumber: '+688',
  //   country: 'TV',
  // },
  // {
  //   label: 'Uganda',
  //   codeNumber: '+256',
  //   country: 'UG',
  // },
  // {
  //   label: 'Ukraine',
  //   codeNumber: '+380',
  //   country: 'UA',
  // },
  // {
  //   label: 'United Arab Emirates',
  //   codeNumber: '+971',
  //   country: 'AE',
  // },
  // {
  //   label: 'United Kingdom',
  //   codeNumber: '+44',
  //   country: 'GB',
  // },
  // {
  //   label: 'United States',
  //   codeNumber: '+1',
  //   country: 'US',
  // },
  // {
  //   label: 'Uruguay',
  //   codeNumber: '+598',
  //   country: 'UY',
  // },
  // {
  //   label: 'Uzbekistan',
  //   codeNumber: '+998',
  //   country: 'UZ',
  // },
  // {
  //   label: 'Vanuatu',
  //   codeNumber: '+678',
  //   country: 'VU',
  // },
  // {
  //   label: 'Venezuela, Bolivarian Republic of Venezuela',
  //   codeNumber: '+58',
  //   country: 'VE',
  // },
  // {
  //   label: 'Vietnam',
  //   codeNumber: '+84',
  //   country: 'VN',
  // },
  // {
  //   label: 'Virgin Islands, British',
  //   codeNumber: '+1 284',
  //   country: 'VG',
  // },
  // {
  //   label: 'Virgin Islands, U.S.',
  //   codeNumber: '+1 340',
  //   country: 'VI',
  // },
  // {
  //   label: 'Wallis and Futuna',
  //   codeNumber: '+681',
  //   country: 'WF',
  // },
  // {
  //   label: 'Yemen',
  //   codeNumber: '+967',
  //   country: 'YE',
  // },
  // {
  //   label: 'Zambia',
  //   codeNumber: '+260',
  //   country: 'ZM',
  // },
  // {
  //   label: 'Zimbabwe',
  //   codeNumber: '+263',
  //   country: 'ZW',
  // },
]

export interface AnalyticCard {
  reportType: string
  count: number
  margin: number
  percentage: number
  percentageAlias?: string
  type?: 'currency' | 'order' | 'percentage'
}

/** Start Date Picker Filter */
export interface DateRangePicker {
  start: number
  end: number
}
/** End Date Picker Filter */

/** Start Line Graph */
export interface GraphData {
  datasets: GraphDataset[]
  labels: string[]
  isShowLegend?: boolean
}

export interface GraphDataset {
  label?: string
  data: number[]
  originData?: any[]
  backgroundColor: string | string[]
  borderColor: string
  pointBackgroundColor: string
  pointBorderColor: string
  pointHoverBackgroundColor: string
  pointHoverBorderColor: string | string[]
  borderDash?: number[]
  borderWidth: number
  pointRadius: number
  pointBorderWidth: number
  hoverBorderWidth: number
  fill?: boolean
  tension?: number
  isBgGradientColor?: boolean
  gradientColorList?: string[]
}

export interface GraphTooltip {
  label: string
  value: {}
}

export type GraphTooltipType = 'currency' | 'text' | 'currency-v2' | 'text-v2'
/** End Line Graph */

export interface CountryListUtil {
  name: string
  countryId: string
  currencyId: string
  langId: string
  currencyLabel: string
  currencySymbol: string
  locale: string
}

/**
 * Phone Code
 */
export interface PhoneCode {
  code: string
  name: string
  phoneInitial: number
  phoneLabel: string
}

export interface Status {
  code: number
  value: string
}

export type FormMode = 'create' | 'edit' | 'duplicate' | 'detail'

export interface DayUtils {
  day: string
  dayIndex: number
  shortcut: string
}
export interface HttpCustomResponse<T> {
  code: string
  status: number
  data: T
}

export interface DownloadRequest {
  queueToken: string
}

export interface Country {
  code: string
  name: string
  phone: string
  availableStore: boolean
}

// ------ Pagination Component -----------

export interface PageSize {
  label: number
  value: number
}

/**
 * @extends on:
 *  - Analytic Model
 *  - Employee Model
 */
export interface PairedDate {
  start: string
  end: string
}

// ---------------------------------------

export interface Timezone {
  code: string
  name: string
  utc: string[]
  utcValue: string
  offset: number
}

export type BrowserType = 'chrome' | 'firefox' | 'safari'
// ---------------------------------------
export type ScreenMode = 'desktop' | 'mobile' | 'tablet'

export interface RouteNavigation {
  commands: any[]
  extras?: NavigationExtras
}
export interface UserInfo {
  name: string
  template: string
  authKey: null
  validated: boolean
  activated: boolean
  active: boolean
  socketToken: string
  subscriptionType: string
  basicIdentityNotComplete: boolean
  emptyPassword: boolean
  countProducts: number
  countMerchantChannel: number
  countIdePage: number
  socialAuthFrom: boolean
  socialRegisterFrom: boolean
  otp: boolean
  lastLogin: string
  role: {
    name: string
    permissions: {}
    revision: number
  }
  verificationModel: null
  email: string
  phoneNumber: string
  phoneCountryCode: string
  storeId: number
  timezone: string
  currency: string
  region: string
  langCode: string
  brandLogoUrl: null
  storeAddress: null
  taxNumber: null
  connectAccount: string
  stripeStatus: string
  configTableManagementEnabled: boolean
  merchantType: MerchantType
  firstLogin: boolean
}

export type MerchantType = 'IDEPOS' | 'PDA'

export interface TabMenu {
  path: string
  name: string
}

export interface IDuration {
  hour: string
  minute: string
}

export interface IResultDuration {
  timestamp: number
  durationText: string
  durationIso: string
}

export interface IErrorDuration {
  data: IResultDuration
  errorMessage: string
}

export const HOUR_TO_SECOND = 3600
export const MIN_TO_SECOND = 60
export interface TourPayloadModel {
  menuType: string
}

export interface TourResponseModel {
  menu: string
  status: boolean
}

export interface TourStepModel {
  element: HTMLElement | string | null
  title: string
  intro: string
}

export type TourStepPosition = 'top' | 'bottom' | 'right' | 'left'

// Video Tutorial
export interface TutorialVideoParam {
  parentName: string
  subMenu: string
}

export interface TutorialVideoResponse {
  menu: string
  priority: number
  thumbnail: string
  video: string[]
  tittle: string
}

//  NPS
export interface NpsPayload {
  feedback: string
  nps: number
}

export interface DropdownModel {
  name: string
  code: string
}

export interface PaginationDataV2<T> {
  content: T
  pageable: PaginationPageable
  last: boolean
  totalPages: number
  totalElements: number
  sort: PaginationSort
  first: boolean
  numberOfElements: number
  size: number
  number: number
  empty: boolean
}

export interface FnbService extends Area {
  selected: boolean
}

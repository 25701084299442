import { SortDirection, TabMenu } from "@models/common.model"


// Performances

export interface IPaginationReq {
    pageNumber: number
    pageSize: number
    sortDirection?: SortDirection
    sortBy?: string
    dataQuery: string
    status?: number
    serviceProduct?: string
    currency?: string
}

export class CPaginationReq implements IPaginationReq {
    pageNumber = 0
    pageSize = 10
    sortDirection: SortDirection = 'DESC'
    dataQuery = ''
}

export interface PaginationPerformanceRes {
    merchantId: number
    merchantName: string
    email: string
    phoneCountryCode: string
    phoneNumber: string
    serviceProducts: string[]
    status: number
    subscriptionPrice: number
    subscriptionJoinDate: number
    subscriptionEndDate: number
    totalSubscriptionPrice: number
    transaction: number
    subscriptionDuration: number
    dateStartDesc: string
    dateEndDesc: string
    transactionDesc: string
    subscriptionPriceDesc: string
    merchantAddress: string
    totalStore: number
    dueDate: number
    releaseDate: number
    invoice: string
    merchantStoreCount: number
    currency: string
}


// Transactions
export interface IPaginationTransactionReq extends IPaginationReq {
    storeId?: number
    orderType?: string
    statusOrder?: number
    paymentMethod?: string
    paymentIntegrationType?: string
    startDate?: number
    endDate?: number
}

export class CPaginationTransactionReq implements IPaginationTransactionReq {
    pageNumber = 0
    pageSize = 10
    sortDirection: SortDirection = 'DESC'
    dataQuery = ''
}

export interface SplitBill {
    orderAt: number
    statusOrder: number
    paymentMethod: string
    amountReceived: number
    feeApplication: number
    cardNumber: string
    countryCode: string
    countryName: string
    currency: string
    statusAlias: string
}

export interface PaginationTransactionRes {
    invoice: string
    orderType: string
    orderAt: number
    statusOrder: number
    paymentMethod: string
    amountReceived: number
    feeApplication: number
    statusAlias: string
    countryCode: string
    countryName: string
    currencyCode: string
    cardNumber: string
    currency: string
    splitBills?: SplitBill[]
    showSplitBills?: boolean
}

export class CPaginationTransactionRes implements PaginationTransactionRes {
    invoice = 'INVQ-1705310312673'
    orderType = 'TAKEOUT'
    orderAt = 1705310312000
    statusOrder = 90
    paymentMethod = 'STRIPE_VISA'
    amountReceived = 25.65000
    feeApplication = 1.13000
    statusAlias = ''
    countryCode = 'SG'
    countryName = 'Singapore'
    currencyCode = 'SGD'
    cardNumber = '-'
    currency = 'SGD'
}

export interface IDownloadTransactionReq extends IPaginationTransactionReq {}

export class CDownloadTransactionReq implements IDownloadTransactionReq {
    pageNumber = 0
    pageSize = 10
    sortDirection: SortDirection = 'DESC'
    dataQuery = ''
}

// Refunds

export interface IPaginationRefundReq extends IPaginationTransactionReq {
    refundType?: string
    statusRefund?: string
}

export class CPaginationRefundReq implements IPaginationRefundReq {
    pageNumber = 0
    pageSize = 10
    sortDirection: SortDirection = 'DESC'
    dataQuery = ''
}

export interface PaginationRefundRes {
    orderId: number
    invoice: string
    referenceInvoice: string
    totalAmount: number
    refundAmount: number
    transactionDate: number
    paymentMethod: string
    refundType: string
    cardNumber: string
    refundRequestDate: number 
    status: string
    completeDate: number
    updatedRefundStatus: string
    updatedBy: number
    updatedAt: number
    showEditStatus: boolean
    statusAlias: string
    refundCompleteDate: number
    countryCode: string
    countryName: string
    currencyCode: string
}

export class CPaginationRefundRes implements PaginationRefundRes {
    orderId = 9648
    invoice = 'INV-471701853661721'
    referenceInvoice = 'INV-471701853548500'
    totalAmount = 1742.39000
    refundAmount = 724.66000
    transactionDate = 1701853662000
    paymentMethod = 'STRIPE_PAY_NOW'
    refundType = 'FULL'
    cardNumber = '9990'
    refundRequestDate = 1701853662000
    status = 'ON_PROGRESS'
    completeDate = 1705901611581
    updatedRefundStatus = 'SUCCESS'
    updatedBy = 865
    updatedAt = 1705901611581
    showEditStatus = false
    statusAlias = ''
    refundCompleteDate = 1705901611581
    countryCode = 'SG'
    countryName = 'Singapore'
    currencyCode = 'SGD'
}

export interface IDownloadRefundReq extends IPaginationRefundReq {}

export class CDownloadRefundReq implements IDownloadRefundReq {
    pageNumber = 0
    pageSize = 10
    sortDirection: SortDirection = 'DESC'
    dataQuery = ''
}

export const PERFORMANCE_TAB_LIST: TabMenu[] = [
    {
        path: 'transaction-table',
        name: 'PERFORMANCE.TAB_TRANSACTION'
    },
    {
        path: 'refund-table',
        name: 'PERFORMANCE.TAB_REFUND'
    },
]

export interface PerformanceDropdownList {
    code: string
    name: string
}

export interface MerchantStoreInfo {
    invoice: string,
    merchantId: number,
    merchantName: string,
    merchantEmail: string,
    merchantAddress: string,
    merchantPhoneCountryCode: string,
    merchantPhoneNumber: string,
    storeId: number,
    storeName: string,
    storeAddress: string,
    storePhoneCountryCode: string,
    storePhoneNumber: string,
    storeCount: number,
    subscriptionDuration: number,
    subscriptionPrice: number,
    totalSubscriptionPrice: number,
    grandTotalSubscriptionPrice: number,
    subscriptionJoinDate: number,
    subscriptionEndDate: number,
    serviceProducts: string[],
    releaseDate: number,
    dueDate: number,
    country: string,
    currency: string,
    tax: number
    freeMonthSubscriptionDuration: number
}


import { isPlatformBrowser } from '@angular/common';
import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DeviceDetectorResolver implements Resolve<boolean> {
  
  isBrowser!: boolean;
  constructor(
    @Inject(PLATFORM_ID) platformId: Object,
    public deviceDetector: DeviceDetectorService
  ) { 
    this.isBrowser = isPlatformBrowser(platformId)
  }
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return of(this.isBrowser);
  }
}
